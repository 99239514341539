import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";
import theme from "styles/theme";

const Intro = ({ rows, valueLabel }) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={5}
      mb={4}
    >
      <Typography
        variant="h1"
        component="h1"
        color={theme.palette.firstGreen}
        style={{
          fontSize: "3.5rem",
        }}
      >
        {t("Panel Control")}
      </Typography>

      {[{ text: t("(주)재신정보"), mt: 1, mb: 0 }].map((item, index) => (
        <Typography
          key={item.text + index}
          variant="h4"
          component="h2"
          color={theme.palette.firstGreen}
          mt={item.mt}
          mb={item.mb}
        >
          {item.text}
        </Typography>
      ))}
    </Box>
  );
};

export default Intro;
