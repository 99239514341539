import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import { Operator } from "classes";

import Page from "components/Page";
import Header from "pages/User/Home/Header";
import FirstLayer from "pages/User/Home/FirstLayer";
import SecondLayer from "pages/User/Home/SecondLayer";
import ThirdLayer from "pages/User/Home/ThirdLayer";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import DataTable from "./DataTableAdmin";
import theme from "styles/theme";
import { useHistory } from "react-router";

import {
  Box,
  Typography,
  Container,
  Grid,
  Modal,
  Paper,
  Button,
  ButtonBase,
  Chip,
  TextField,
} from "@mui/material";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const mdTheme = createTheme();

const Home = () => {
  const history = useHistory();

  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const ccInput = useInput("");
  const [Modal, modalopen, close, isOpen] = useModal("modal-div", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [selectedCCIndex, setSelectedCCIndex] = React.useState(0);
  const [selectedCC, setSelectedCC] = React.useState(null);

  const [operator, setoperator] = React.useState(null);
  const [CCs, setCCs] = React.useState(null);
  const [panels, setPannel] = React.useState(null);
  const [operators, setOperators] = React.useState(null);
  const [warningCounts, setWarningCounts] = React.useState(null);

  const [refreshInterval, setRefreshInterval] = React.useState(null);

  const registerInterval = () => {
    if (refreshInterval) {
      return;
    }
    const interval = setInterval(async function () {
      setRefresh(true);
    }, 30 * 1000);
    setRefreshInterval(interval);
  };
  const unregisterInterval = () => {
    clearInterval(refreshInterval);
    setRefreshInterval(null);
  };

  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);
    const ccData = await operator.getAdminCCs();
    //ccData가 powerTable로 들어가기

    setCCs(ccData);
    if (!ccData.length) {
      return;
    }
    setSelectedCC(ccData[0]);
    //맨처음 씨씨 리스트에 선택되게
    //셀렉트된 CC의 패널 가져오기

    //요건 함수에 넣지 말기

    //분전 데이터 받아오기
    const panelData = await operator.getAdminPanels(ccData[0].id);
    setPannel(panelData);
    // cc 별 운영자 계정 받아오기
    const operatorData = await operator.getAdminOperators(ccData[0].id);
    setOperators(operatorData);

    //파워 테이블에 넣기
    //class 와 api 만들기
    const warningCountData = await operator.countWarnings();
    console.log("워닝 카운트", warningCountData.result.count);
    setWarningCounts(warningCountData.result.count);
    setData(panelData);

    registerInterval();

    return () => unregisterInterval();
  });

  React.useEffect(() => {
    (async () => {
      const operator = new Operator(user.id);
      setoperator(operator);
      const ccData = await operator.getAdminCCs();
      //ccData가 powerTable로 들어가기
      setCCs(ccData);
      if (!ccData.length) {
        return;
      }
      setSelectedCC(ccData[selectedCCIndex]);
      //맨처음 씨씨 리스트에 선택되게
      //셀렉트된 CC의 패널 가져오기
      //요건 함수에 넣지 말기
      //분전 데이터 받아오기
      const panelData = await operator.getAdminPanels(
        ccData[selectedCCIndex].id
      );
      console.log("패널데이터", panelData);
      setPannel(panelData);
      setData(panelData);
      // cc 별 운영자 계정 받아오기
      const operatorData = await operator.getAdminOperators(
        ccData[selectedCCIndex].id
      );
      setOperators(operatorData);
      //파워 테이블에 넣기
      //class 와 api 만들기
      const warningCountData = await operator.countWarnings();
      setWarningCounts(warningCountData.result.count);
      setData("data.result");
      // setData(data.result);
    })();
  }, [selectedCCIndex]);

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const operator = new Operator(user.id);
        setoperator(operator);
        const ccData = await operator.getAdminCCs();
        //ccData가 powerTable로 들어가기

        setCCs(ccData);
        if (!ccData.length) {
          return;
        }
        setSelectedCC(ccData[selectedCCIndex]);
        //맨처음 씨씨 리스트에 선택되게
        //셀렉트된 CC의 패널 가져오기

        //요건 함수에 넣지 말기

        //분전 데이터 받아오기
        const panelData = await operator.getAdminPanels(
          ccData[selectedCCIndex].id
        );
        setPannel(panelData);
        setData(panelData);
        // cc 별 운영자 계정 받아오기
        const operatorData = await operator.getAdminOperators(
          ccData[selectedCCIndex].id
        );
        setOperators(operatorData);

        //파워 테이블에 넣기
        //class 와 api 만들기

        setData("data.result");
        setRefresh(false);
        // setData(data.result);
      })();
    }
  }, [refresh]);

  const mainListItems = (
    <React.Fragment>
      {CCs &&
        CCs.map((v, i) => (
          <ListItemButton
            onClick={() => {
              setSelectedCCIndex(i);
              setRefresh(true);
            }}
            selected={selectedCCIndex === i}
            dense
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={v.title} />
          </ListItemButton>
        ))}
    </React.Fragment>
  );

  const secondaryListItems = (
    <React.Fragment>
      {/* <ListSubheader component="div" inset>
        추가
      </ListSubheader> */}
      <ListItemButton
        onClick={() => {
          return modalopen();
        }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="추가" />
      </ListItemButton>
    </React.Fragment>
  );

  const params = { refresh, setRefresh, ...data };

  // const CCCreationModal = (props) => {
  //   return (

  //   );
  // };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          {/* <PowerTable></PowerTable> */}
          <ThemeProvider theme={mdTheme}>
            <Modal sx={{ zIndex: 99999 }}>
              <React.Fragment>
                <Container>
                  <Box sx={{ width: "90vw" }}>
                    <Paper
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ p: 2 }}
                    >
                      <Typography my={2}>CC 생성</Typography>

                      <Box>
                        {[
                          {
                            placeholder: t("CC명"),
                            type: "id",
                            value: ccInput.value,
                            onChange: ccInput.onChange,
                          },
                        ].map((option) => {
                          return (
                            <TextField
                              inputProps={{
                                style: { color: theme.palette.firstGreen },
                              }}
                              sx={{
                                mt: 2,
                                "& label.Mui-focused": {
                                  color: theme.palette.firstGreen,
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: theme.palette.firstGreen,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: theme.palette.firstGreen,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.palette.firstGreen,
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.palette.firstGreen,
                                  },
                                },
                              }}
                              fullWidth
                              required
                              key={option.placeholder}
                              size="small"
                              {...option}
                            />
                          );
                        })}
                        <Box mt={3}>
                          <Button
                            onClick={async () => {
                              if (ccInput.value === "") {
                                alert("타이틀을 입력해 주세요");
                                return;
                              }
                              const data = await operator.createAdminCC(
                                ccInput.value
                              );
                              if (!data) {
                                alert("생성 실패");
                                return;
                              } else {
                                alert("생성 완료");
                                setRefresh(true);
                              }
                              close();
                            }}
                            fullWidth
                          >
                            {t("CC 생성")}
                          </Button>
                          <Button
                            onClick={async () => {
                              close();
                            }}
                            fullWidth
                          >
                            {t("닫기")}
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Container>
              </React.Fragment>
            </Modal>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <AppBar position="absolute" open={open}>
                <Toolbar
                  sx={{
                    pr: "24px", // keep right padding when drawer closed
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                      marginRight: "36px",
                      ...(open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                  >
                    {selectedCC.title}
                  </Typography>
                  <IconButton color="inherit">
                    <Badge
                      badgeContent={warningCounts}
                      color="secondary"
                      onClick={() => {
                        unregisterInterval();
                        history.push("/warnings");
                      }}
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Drawer variant="permanent" open={open} sx={{ zIndex: 50 }}>
                <Toolbar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    px: [1],
                  }}
                >
                  <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                  </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                  {mainListItems}
                  <Divider sx={{ my: 1 }} />
                  {secondaryListItems}
                </List>
              </Drawer>
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <Toolbar />
                {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
                <div id="modal-div">
                  <DataTable
                    cc={selectedCC}
                    panel={panels}
                    setPanel={setPannel}
                    operators={operators}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  ></DataTable>
                </div>
                {/* <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={9}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 240,
                        }}
                      >
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 240,
                        }}
                      >
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                      </Paper>
                    </Grid>
                  </Grid> */}
                {/* </Container> */}
              </Box>
            </Box>
          </ThemeProvider>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
