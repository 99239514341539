import React from "react";
import ExpandableTable from "pages/User/Home/ExpandableTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import {
  Box,
  Typography,
  Container,
  Grid,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import GlobalContext from "context/GlobalContext";
import theme from "styles/theme";
import TextField from "components/TextField";
import Button from "components/Button";

import LayerTitle from "components/LayerTitle";
import numeral from "numeral";
import { changeMatic } from "utils";
import axios from "axios";
import { httpHeader } from "utils";
import { CheckCircleOutline } from "@mui/icons-material";

const Home = (params) => {
  const { user, selectedAddress, network } = React.useContext(GlobalContext);
  const classes = useStyles();
  const referral = `${process.env.REACT_APP_WEBSITE}/${user.email}`;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPackage, setSelectedPackage] = React.useState(
    params.packages[0].price
  );
  const [balance, setBalance] = React.useState(0);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const onCopy = () => {
    enqueueSnackbar(t("클립보드로 복사되었습니다."), { variant: "info" });
  };

  return (
    <Container maxWidth="lg">
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 500,
            width: "90vw",
            maxHeight: 250,
            height: "45vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            px: 3,
          }}
        >
          {isSuccess ? (
            <React.Fragment>
              <CheckCircleOutline
                sx={{ fontSize: 48, color: theme.palette.firstGreen }}
              />
              <Typography sx={{ mb: 1, mt: 3 }}>
                {t("패키지 구매가 완료되었습니다.")}
              </Typography>
              <Typography sx={{ mb: 3 }}>
                {t("송금 트랜잭션이 승인되어야 패키지가 구매처리됩니다.")}
              </Typography>
              <Box
                sx={{ width: 140 }}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.selectedButton}
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_EXPLORER_TX}${isSuccess}`
                    );
                  }}
                >
                  {t("TX 조회")}
                </Button>
                <Button
                  className={classes.selectedButton}
                  onClick={() => {
                    setIsSuccess(null);
                    setOpen(false);
                  }}
                >
                  {t("닫기")}
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CircularProgress size={36} color="warning" />
              <Typography sx={{ mb: 1, mt: 3 }}>
                {t("패키지 구매를 진행중입니다.")}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                {t("메타마스크와 연동하여 폴리곤을 결제합니다.")}
              </Typography>
              <Typography align="center" color="red">
                (
                {t(
                  "패키지 구매 금액과 결제 기록이 다를경우 패키지 구매처리가 되지 않습니다."
                )}
                )
              </Typography>
            </React.Fragment>
          )}
        </Box>
      </Modal>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={5}
      >
        <Typography
          variant="h1"
          component="h1"
          color={theme.palette.firstGreen}
          style={{
            background:
              "linear-gradient(90deg, rgba(229,239,196,1) 0%, rgba(69,173,168,1) 35%, rgba(89,79,79,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "3.5rem",
          }}
        >
          {t("폴리곤 파워")}
        </Typography>

        {[
          { text: t("스마트 폴리곤 프로젝트"), mt: 4, mb: 0 },
          { text: t("이더리움의 확장 솔루션"), mt: 1, mb: 3 },
        ].map((item, index) => (
          <Typography
            key={item.text + index}
            variant="h3"
            component="h2"
            mt={item.mt}
            mb={item.mb}
            style={{
              background: `-webkit-linear-gradient(45deg, #547980 30%, #594f4f 90%)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {item.text}
          </Typography>
        ))}
        {[
          {
            text: t("폴리곤 MATIC"),
            letterSpacing: "0.3rem",
          },
          {
            text: t("부자가되는 플랜!"),
            letterSpacing: "0.3rem",
          },
          {
            text: t("스마트 폴리곤 플랜!"),
            letterSpacing: "0.3rem",
          },
          {
            text: t("스마트 계약기반의 폴리곤 프로젝트"),
            letterSpacing: "0.1rem",
          },
        ].map((item, index) => (
          <Typography
            key={item.text + index}
            variant="h4"
            component="h4"
            color={theme.palette.white}
            sx={{
              opacity: 0.8,
              fontWeight: "400",
              letterSpacing: item.letterSpacing,
            }}
            mt={1}
          >
            {item.text}
          </Typography>
        ))}
      </Box>
      <Box
        my={6}
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          background: "#fff",
          borderRadius: "5px",
          color: "#000",
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            px={1}
            py={1}
            color={theme.palette.fourthGrey}
            sx={{ wordBreak: "break-all" }}
          >
            {referral}
          </Typography>
          <CopyToClipboard text={params.config.admin_address} onCopy={onCopy}>
            <ContentCopyIcon
              sx={{ padding: "10px", width: "45px", height: "45px" }}
            />
          </CopyToClipboard>
        </Box>
      </Box>

      <LayerTitle title={t("폴리곤 챌린지")} />
      <ExpandableTable rows={params.bodyRanking} valueLabel="재투자 금액" />
      <ExpandableTable
        rows={params.recommendRanking}
        valueLabel="추천인 인원수"
      />
      <LayerTitle id="a" title={t("폴리곤 패키지")} />
      <Grid
        justifyContent="center"
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {/* {params.packages.map((item, index) => (
          <Grid item xs={1} md={1} key={index + "package"}>
            <Button
              key={index + "pakage"}
              variant={item.price !== selectedPackage ? "contained" : "contained"}
              className={item.price !== selectedPackage ? classes.button : classes.selectedButton}
              onClick={() => {
                // 기능
                setSelectedPackage(item.price);
              }}
            >
              {numeral(item.price).format()} {process.env.REACT_APP_TICKER}
            </Button>
          </Grid>
        ))} */}
        <Grid item xs={12} md={12}>
          <Button
            fullWidth
            sx={{ height: 80, fontSize: 22 }}
            variant={
              params.packages[0].price !== selectedPackage
                ? "contained"
                : "contained"
            }
            className={
              params.packages[0].price !== selectedPackage
                ? classes.button
                : classes.selectedButton
            }
            onClick={() => {
              // 기능
              setSelectedPackage(params.packages[0].price);
            }}
          >
            {numeral(params.packages[0].price).format()}{" "}
            {process.env.REACT_APP_TICKER}
          </Button>
        </Grid>
      </Grid>

      <Typography my={1} mt={2} align="right" color={theme.palette.white}>
        {t("내 폴리곤 지갑주소")}
      </Typography>
      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          background: "#fff",
          borderRadius: "5px",
          color: "#000",
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            px={1}
            py={1}
            color={theme.palette.fourthGrey}
            sx={{ wordBreak: "break-all" }}
          >
            {selectedAddress}
          </Typography>
          <CopyToClipboard text={params.config.admin_address} onCopy={onCopy}>
            <ContentCopyIcon
              sx={{ padding: "10px", width: "45px", height: "45px" }}
            />
          </CopyToClipboard>
        </Box>
      </Box>

      <Box component="form" noValidate autoComplete="off">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
          align
        >
          <Typography mb={1} color={theme.palette.white}>
            {t("보유 폴리곤")}
          </Typography>
          <TextField
            inputProps={{
              style: {
                textAlign: "right",
                color:
                  selectedPackage > balance ? "red" : theme.palette.firstGreen,
              },
            }}
            value={numeral(balance).format("0,0.[0000]") + " MATIC"}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
          align
          mt={8}
        >
          <Typography mb={1} color={theme.palette.white}>
            {t("최종 입급 폴리곤 수량")}
          </Typography>
          <TextField
            value={numeral(selectedPackage).format() + " MATIC"}
            inputProps={{
              style: {
                color: theme.palette.firstGreen,
                textAlign: "right",
                fontSize: "1.5rem",
                fontWeight: "bold",
              },
            }}
          />
        </Box>
        <Box py={3} display="flex" justifyContent="center" alignItems="center">
          {network === process.env.REACT_APP_NETWORK_ID ? (
            <Button
              onClick={() => {}}
              className={classes.selectedButton}
              size="large"
              sx={{ width: 240 }}
            >
              {t("확인")}
            </Button>
          ) : (
            <Button
              onClick={() => {
                enqueueSnackbar(
                  t(
                    "폴리곤 네트워크가 아닙니다. 메타마스크 설정을 폴리곤 네트워크로 변경해주세요."
                  ),
                  {
                    variant: "error",
                  }
                );
                changeMatic();
              }}
              size="large"
              sx={{
                width: "30%",
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              {t("확인")}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.lightGrey,
    color: theme.palette.darkGrey,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
  },
  selectedButton: {
    background:
      "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    color: theme.palette.lightGrey,
    backgroundColor: theme.palette.firstGreen,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
  },
}));

export default Home;
