import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";

import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Circuit from "resources/images/circuit.png";
import Power from "resources/images/power.png";

import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import { httpHeader } from "utils";
import Graph from "pages/User/Graph.js";
import { Operator } from "classes";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Box,
  Chip,
  Typography,
  Container,
  Grid,
  Modal,
  Paper,
  Button,
  ButtonBase,
  Card,
  TextField,
  Link,
} from "@mui/material";
import { useModal } from "react-hooks-use-modal";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Home = ({ match }) => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [selectedcb, setSelectedcb] = React.useState(null);
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [selectedPanel, setSelectedPanel] = React.useState(null);
  const { cc_id } = match.params;
  const { panel_id } = match.params;
  const [isLoading, setIsLoading] = React.useState(true);

  function textFieldChanged(e, cbobj) {
    console.log(cbobj.id);
    const cbId = cbobj.id;
    //숫자 확인
    console.log(typeof e.target.value);
    const chnagedValue = e.target.value;
    if (isNaN(chnagedValue)) {
      alert("숫자만 입력해 주세요");
      setData(data);
      return;
    }
    console.log("data", data);
    const copiedData = data.map((v, i) => {
      if (v.id === cbId) {
        return { ...v, alias: Number(chnagedValue) };
      } else {
        return v;
      }
    });
    console.log(copiedData);
    setData(copiedData);
  }
  function checkStateChange(checkState, cb_id) {
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      setTimeout(async function () {
        const cbState = await operator.getCBStatusSwitch(cb_id);
        // console.log("씨비 스테이트", cbState);
        setIsLoading(false);
        if (!cbState.result) {
          resolve(false);
          return;
        }
        if (cbState.result.switch === checkState) {
          resolve(true);
          return;
        } else {
          resolve(false);
          return;
        }
      }, 5000);
    });
  }

  const leftCircuit = (cbobj) => {
    return (
      <Paper
        flexDirection="row"
        alignContent="center"
        display="flex"
        sx={{ p: 1, m: 1 }}
      >
        {/* container */}
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{ height: "44px" }}
        >
          <Grid xs={1} sx={{ border: "solid" }} item></Grid>
          <Grid xs={2} sx={{}} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {/* //여기 */}
              <TextField
                id="outlined-size-small"
                defaultValue={cbobj.alias}
                onChange={(e) => textFieldChanged(e, cbobj)}
                size="small"
              />
              {/* #{cbobj.alias} */}
            </Typography>
          </Grid>
          <Grid xs={1} sx={{ border: "solid" }} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {cbobj.status && cbobj.status.amp}A
            </Typography>
          </Grid>
          <Grid
            xs={1}
            onClick={() => {
              setSelectedcb(cbobj);
              open();
            }}
            sx={{
              border: "solid",
              backgroundColor:
                cbobj.status && cbobj.status.switch ? "red" : "green",
            }}
            item
          >
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {cbobj.status && cbobj.status.switch ? "ON" : "OFF"}
            </Typography>
          </Grid>
          {/* image (6) */}
          <Grid
            xs={1}
            sx={{
              backgroundImage: `url(${Circuit})`,
              height: "32px",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            item
          ></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
        </Grid>
      </Paper>
    );
  };
  const rightCircuit = (cbobj) => {
    return (
      <Paper
        flexDirection="row"
        alignContent="center"
        display="flex"
        sx={{ p: 1, m: 1 }}
      >
        {/* container */}
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{ height: "44px" }}
        >
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item>
            {/* 이장협 */}
          </Grid>

          {/* image (6) */}
          <Grid
            xs={1}
            sx={{
              backgroundImage: `url(${Circuit})`,
              height: "32px",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            item
          ></Grid>
          <Grid
            xs={1}
            onClick={() => {
              setSelectedcb(cbobj);
              open();
            }}
            sx={{
              border: "solid",
              backgroundColor:
                cbobj.status && cbobj.status.switch ? "red" : "green",
            }}
            item
          >
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {cbobj.status && cbobj.status.switch ? "ON" : "OFF"}
            </Typography>
          </Grid>

          <Grid xs={1} sx={{ border: "solid" }} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {cbobj.status?.amp ?? "0"}A
            </Typography>
          </Grid>
          {/* number */}
          <Grid xs={2} sx={{}} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {/* //여기 */}
              <TextField
                id="outlined-size-small"
                defaultValue={cbobj.alias}
                onChange={(e) => textFieldChanged(e, cbobj)}
                size="small"
              />
              {/* #{cbobj.alias} */}
            </Typography>
          </Grid>

          <Grid xs={1} sx={{ border: "solid" }} item></Grid>
          <Grid xs={1} sx={{ border: "solid" }} item></Grid>
        </Grid>
      </Paper>
    );
  };

  const centerCircuit = (cbobj) => {
    return (
      <Paper sx={{ p: 1, m: 1, height: "100%" }}>
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{
            padding: "0px",
            height: "85px",
          }}
        >
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid
            xs={2}
            sx={{ border: "solid" }}
            display="flex"
            flexDirection="column"
            alignContent="center"
          >
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.alias}번</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>
                {cbobj.amp ?? "0"}A
              </Typography>
            </Box>
          </Grid>

          <Grid
            xs={2}
            sx={{
              border: "solid",
              padding: "0px",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundImage: `url(${Circuit})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    // setSelectedcb(cbobj);
                    // open();
                  }}
                  variant="contained"
                  color={cbobj.status?.switch ? "error" : "primary"}
                >
                  {cbobj.status?.switch ? "ON" : "OFF"}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
        </Grid>
      </Paper>
    );
  };

  const centerPower = (cbobj, cbNext) => {
    return (
      <Paper
        flexDirection="row"
        alignContent="center"
        display="flex"
        sx={{ p: 1, m: 1 }}
      >
        {/* container */}
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{ height: "44px" }}
        >
          <Grid xs={2} sx={{}} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {/* //여기 */}
              <TextField
                id="outlined-size-small"
                defaultValue={cbobj.alias}
                onChange={(e) => textFieldChanged(e, cbobj)}
                size="small"
              />
              {/* #{cbobj.alias} */}
            </Typography>
          </Grid>
          <Grid xs={1} sx={{ border: "solid" }} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {cbobj.status?.amp ?? "0"}A
            </Typography>
          </Grid>
          <Grid
            xs={1}
            onClick={() => {
              setSelectedcb(cbobj);
              open();
            }}
            sx={{
              border: "solid",
              backgroundColor: cbobj.status?.switch ? "red" : "green",
            }}
            item
          >
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {cbobj.switch ? "ON" : "OFF"}
            </Typography>
          </Grid>
          {/* right image (6) */}
          <Grid
            xs={1}
            sx={{
              backgroundImage: `url(${Circuit})`,
              height: "32px",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            item
          ></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>

          {/* left image (6) */}
          {cbNext && (
            <React.Fragment>
              <Grid
                xs={1}
                sx={{
                  backgroundImage: `url(${Circuit})`,
                  height: "32px",
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
                item
              ></Grid>
              <Grid
                xs={1}
                onClick={() => {
                  setSelectedcb(cbNext);
                  open();
                }}
                sx={{
                  border: "solid",
                  backgroundColor: cbNext.status?.switch ? "red" : "green",
                }}
                item
              >
                <Typography
                  align="center"
                  noWrap={false}
                  sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
                >
                  {cbobj.switch ? "ON" : "OFF"}
                </Typography>
              </Grid>

              <Grid xs={1} sx={{ border: "solid" }} item>
                <Typography
                  align="center"
                  noWrap={false}
                  sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
                >
                  {cbNext.status?.amp ?? "0"}A
                </Typography>
              </Grid>
              {/* number */}
              <Grid xs={2} sx={{}} item>
                <Typography
                  align="center"
                  noWrap={false}
                  sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
                >
                  {/* //여기 */}
                  <TextField
                    id="outlined-size-small"
                    defaultValue={cbNext.alias}
                    onChange={(e) => textFieldChanged(e, cbNext)}
                    size="small"
                  />
                  {/* #{cbobj.alias} */}
                </Typography>
              </Grid>
            </React.Fragment>
          )}
          {!cbNext && (
            <React.Fragment>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
            </React.Fragment>
          )}
        </Grid>
      </Paper>
    );
  };

  const powerDrawing = (power) => {
    const powerSwitch = (power) => {
      return (
        <Paper
          flexDirection="row"
          alignContent="center"
          display="flex"
          sx={{ p: 1, m: 1, height: "100%" }}
        >
          <Grid
            container
            flexDirection="row"
            alignContent="center"
            display="flex"
            justifyContent="center"
            sx={{}}
          >
            <Grid xs={2} sx={{}}></Grid>
            <Grid xs={1} sx={{}} item></Grid>
            <Grid
              xs={2}
              sx={{}}
              container
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.amp}A</Typography>
              </Box>
              <Box
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.kw}kW</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="center"
                alignContent="center"
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.volt}V</Typography>
              </Box>
            </Grid>
            <Grid xs={3} sx={{ padding: "0px" }}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundImage: `url(${Power})`,

                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    width: "40%",
                    height: "100%",
                  }}
                >
                  <Chip
                    variant="contained"
                    onClick={async () => {
                      const isOn = selectedPanel.switch;
                      const dialog = isOn
                        ? "전원을 끄시겠습니까"
                        : "전원을 키시겠습니까";

                      const confirm = window.confirm(
                        `${selectedPanel.title} ${dialog} `
                      );
                      if (confirm) {
                        const { status: a } = await operator.setPanelSwitch(
                          panel_id,
                          !isOn
                        );

                        if (a) {
                          enqueueSnackbar("성공", { variant: "success" });
                          setRefresh(true);
                          setRefresh(false);

                          // window.location.reload(false);
                        } else {
                          enqueueSnackbar("실패", { variant: "error" });
                          // window.location.reload(false);
                          setRefresh(true);
                          setRefresh(false);
                        }
                      }
                    }}
                    label={power.switch ? "ON" : "OFF"}
                    size="small"
                    color={power.switch ? "error" : "primary"}
                  ></Chip>
                </Box>
              </Box>
            </Grid>
            <Grid xs={1} sx={{}}></Grid>
            <Grid xs={1} sx={{}}></Grid>
            <Grid xs={2} sx={{}} item></Grid>
          </Grid>
        </Paper>
      );
    };

    let circuitSwitch = (type) => {
      if (type === "left") {
        return (
          data &&
          data.map((v, i) => {
            return leftCircuit(v, "c");
          })
        );
      } else if (type === "right") {
        return (
          data &&
          data.map((v, i) => {
            return rightCircuit(v, "c");
          })
        );
      } else {
        return (
          data &&
          data.map((v, i) => {
            if (i % 2 === 0) {
              return centerPower(v, data[i + 1]);
            } else {
              return null;
            }
          })
        );
      }
    };
    return (
      <React.Fragment>
        {/* {user.is_admin && powerSwitch(selectedPanel)} */}
        {circuitSwitch(selectedPanel.direction)}
      </React.Fragment>
    );
  };

  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);

    if (user.is_admin) {
      const cc = await operator.getCC(cc_id);
      setcc(cc.result);
    } else {
      const cc = await operator.getCCs();
      console.log("어드민 cc", cc);
      setcc(cc.result);
    }
    operator.getUsers();

    const cb = await operator.getCBs(panel_id);
    const sorted = cb.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    //sorted에 alias를 useInput으로 처리하기
    console.log("sorted", sorted);
    //useInput을 만들기

    let totalamp = 0;
    cb.map((v) => {
      totalamp += v.amp;
    });
    console.log(totalamp);

    let panel = await operator.getPanelbyPanelId(panel_id);

    setSelectedPanel(panel);
    setIsLoading(false);
    setData(sorted);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const operator = new Operator(user.id);
        setoperator(operator);

        if (user.is_admin) {
          const cc = await operator.getCC(cc_id);
          setcc(cc.result);
        } else {
          const cc = await operator.getCCs();
          setcc(cc.result);
        }

        operator.getUsers();

        const cb = await operator.getCBs(panel_id);
        const sorted = cb.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }
          if (a.index < b.index) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        let totalamp = 0;
        cb.map((v) => {
          totalamp += v.amp;
        });
        console.log(totalamp);

        let panel = await operator.getPanelbyPanelId(panel_id);

        setSelectedPanel(panel);

        setData(sorted);
      })();
    }
  }, [refresh]);

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {/* <Header /> */}

      {data ? (
        <React.Fragment>
          <Card
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100vw", height: "44px" }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "100%", textAlign: "center" }}
            >
              <ArrowBackIcon
                onClick={() => {
                  return history.goBack();
                }}
                sx={{ m: 1 }}
              />
              <Typography sx={{ mr: 4 }}>
                {cc.title} {selectedPanel.title}
              </Typography>

              <div></div>
            </Box>
          </Card>

          <Card sx={{ width: "100vw", height: "44px" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{ height: "100%", textAlign: "center" }}
            >
              {/* <Link href={`/schedule/${cc_id}/${panel_id}`} underline={"none"}> */}
              <Typography variant="body2"></Typography>
              {/* </Link> */}
              <Link
                // href={`/alias/${cc_id}/${panel_id}`}
                underline="none"
                sx={{}}
                onClick={async () => {
                  // const checkAlias
                  const aliasData = data;
                  const updateAliasAPI = await operator.updateAlias(aliasData);
                  if (!updateAliasAPI.status) {
                    alert("업데이트 실패 : 에러코드 3044");
                    return;
                  }
                  alert("업데이트 성공");
                  setRefresh(true);
                  return;
                }}
              >
                <Typography variant="body2">저장</Typography>
              </Link>
            </Box>
          </Card>
          <Box>{powerDrawing(selectedPanel, "center")}</Box>

          <Modal>
            <Box sx={{ width: "90vw" }}>
              <Paper>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{}}
                >
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    sx={{ m: 1 }}
                  >
                    {selectedcb && selectedcb.alias}번 차단기
                  </Typography>
                </Box>

                <Container>
                  <Paper sx={{ p: 1, mb: 2, mx: 1 }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        center
                        sx={{ p: 1 }}
                      >
                        {"DB id :" + String(selectedcb && selectedcb.id)}
                      </Typography>
                    </Box>
                  </Paper>
                </Container>

                <Container>
                  {selectedcb && <Box>{centerCircuit(selectedcb)}</Box>}
                  <Typography
                    variant="body2"
                    component="div"
                    center
                    sx={{ p: 1, mx: 1 }}
                  >
                    스위치
                  </Typography>
                  {isLoading && (
                    <React.Fragment>
                      <Container>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </Container>
                    </React.Fragment>
                  )}
                  <Paper sx={{ p: 1, mb: 2, mx: 1 }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        color="error"
                        onClick={async () => {
                          const dialog = "전원을 키시겠습니까";

                          const confirm = window.confirm(
                            `${selectedcb.alias}차단기 ${dialog} `
                          );
                          if (confirm) {
                            const { status: a, error } =
                              await operator.setCBSwitch(selectedcb.id, 1);

                            //프로미스로 셋 타임아웃 어웨이트
                            const checkResult = await checkStateChange(
                              1,
                              selectedcb.id
                            );
                            console.log("오브젝트 없을떄", checkResult);

                            if (checkResult) {
                              enqueueSnackbar("성공", { variant: "success" });
                              let changed = selectedcb;
                              changed.switch = true;
                              setSelectedcb(changed);
                              setRefresh(true);
                              setRefresh(false);
                            } else {
                              enqueueSnackbar("기기 작동에 실패 했습니다", {
                                variant: "error",
                              });
                              setRefresh(true);
                              setRefresh(false);
                            }
                            //5초 기다리고 operator.getCB_status 값 가져와서 비교
                          }
                        }}
                      >
                        on
                      </Button>
                      <Button
                        color="primary"
                        onClick={async () => {
                          const dialog = "전원을 끄시겠습니까";

                          const confirm = window.confirm(
                            `${selectedcb.alias}번 차단기 ${dialog} `
                          );

                          if (confirm) {
                            const { status: a } = await operator.setCBSwitch(
                              selectedcb.id,
                              0
                            ); //프로미스로 셋 타임아웃 어웨이트
                            const checkResult = await checkStateChange(
                              0,
                              selectedcb.id
                            );
                            console.log("오브젝트 없을떄", checkResult);
                            if (checkResult) {
                              enqueueSnackbar("성공", { variant: "success" });
                              setRefresh(true);
                              setRefresh(false);
                              let changed = selectedcb;
                              changed.switch = 0;
                              setSelectedcb(changed);
                            } else {
                              enqueueSnackbar("기기 작동에 실패 했습니다", {
                                variant: "error",
                              });
                              setRefresh(true);
                              setRefresh(false);
                            }
                          }
                        }}
                        variant="contained"
                        color="primary"
                      >
                        off
                      </Button>
                    </Box>
                  </Paper>
                </Container>

                <Container sx={{ mx: 1 }}>
                  <Typography
                    variant="body2"
                    component="div"
                    center
                    sx={{ p: 1 }}
                  >
                    사용량
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        p: 2,
                      }}
                    >
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 140,
                        }}
                      >
                        <Graph />
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button onClick={close}>닫기</Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
