import numeral from "numeral";
import moment from "moment";

export const httpHeader = () => ({
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  },
});

export const emailMasking = (email) => {
  let converted = ``;
  try {
    const split = email.split("@");
    for (let i = 0; i < split[0].length; i++) {
      if (i < 3) {
        converted += split[0][i];
      } else {
        converted += `*`;
      }
    }
    converted += "@";
    for (let i = 0; i < split[1].length; i++) {
      if (i < 1) {
        converted += split[1][i];
      } else if (i > split[1].length - 5) {
        converted += split[1][i];
      } else {
        converted += `*`;
      }
    }
  } catch (e) {
    return converted;
  }

  return converted;
};

export const gridHeaderFactory = (column, table) => {
  switch (column) {
    case "id": {
      return "번호";
    }
    case "admin_address": {
      return "어드민 지갑주소(폴리곤 입금주소)";
    }
    case "percentage": {
      return "퍼센트";
    }
    case "reward_type": {
      return "보상 유형";
    }
    case "point": {
      return "포인트";
    }
    case "point_type": {
      return "포인트 유형";
    }
    case "created_at": {
      return "생성일";
    }
    case "email": {
      return "이메일";
    }
    case "package_price": {
      return "패키지 가격";
    }
    case "up_line": {
      return "업라인 범위";
    }
    case "down_line": {
      return "다운라인 범위";
    }
    case "type": {
      return "타입";
    }
    case "price": {
      return "금액";
    }
    case "recommend_count": {
      return "추천인 범위";
    }
    case "withdraw_body": {
      return "출금 비율(몸값)";
    }
    case "withdraw_ratio": {
      return "출금 비율(실제출금)";
    }
    case "from_email": {
      return "지급자 이메일";
    }
    case "reward_email": {
      return "수령자 이메일";
    }
    case "reward_point": {
      return "보상 포인트";
    }
    case "recommend": {
      return "추천인";
    }
    case "is_buy": {
      return "최초구매여부";
    }
    case "withdraw_per": {
      return "수수료 비율";
    }
    case "commission_point": {
      return "출금 수수료";
    }
    case "get_point": {
      return "실제 출금액";
    }
    case "is_withdraw": {
      return "지급 여부";
    }
    default:
      return column;
  }
};

export const errorFactory = (code, action = () => null) => {
  try {
    switch (code) {
      case "duplicate_email": {
        return "중복된 이메일입니다.";
      }
      case "invalid_recommend": {
        return "올바르지 않은 추천인입니다.";
      }
      case "invalid_password": {
        return "올바르지 않은 비밀번호입니다.";
      }
      case "balance_too_low": {
        return "잔액이 부족합니다.";
      }
      case "invalid_amount": {
        return "올바르지 않은 수량입니다.";
      }
      case "invalid_user": {
        return "올바르지 않은 이메일 혹은 비밀번호입니다.";
      }
      case "invalid_token": {
        return "올바르지 않은 인증번호입니다.";
      }
      case "email_timeout": {
        return "인증번호 유효기간이 지났습니다. 인증번호를 재전송 후 다시 진행해주세요.";
      }
      default: {
        return "";
      }
    }
  } catch {
  } finally {
    action();
  }
};

export const changeMatic = async () => {
  await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x4718219",
        chainName: "Plumchain",
        nativeCurrency: {
          name: "Plumchain Mainnet",
          symbol: "PLC", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ["https://node1.plc.plumchain.xyz/https"],
        blockExplorerUrls: ["https://plumscan.xyz"],
      },
    ],
  });
  // await window.ethereum.request({
  //   method: "wallet_addEthereumChain",
  //   params: [
  //     {
  //       chainId: "0x89",
  //       chainName: "Matic",
  //       nativeCurrency: {
  //         name: "Matic Mainnet",
  //         symbol: "MATIC",
  //         decimals: 18,
  //       },
  //       rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
  //       blockExplorerUrls: ["https://polygonscan.com/"],
  //     },
  //   ],
  // });
};

export const nodeFactoryCommunity = (list, index) => {
  const node = (item) => {
    return {
      name: item.email,
      id: item.email,
      parent_id: index === 0 ? null : item.parent_email,
      detail: item,
      children: [],
    };
  };

  return list.map((item, index) => {
    return node(item, index);
  });
};

export const nodeFactory = (list, root) => {
  const ROOT = {
    name: root.parent_email,
    id: root.parent_email,
    parent_id: null,
    detail: root,
    children: [],
  };
  const node = (item) => {
    return {
      name: item.email,
      id: item.email,
      parent_id: item.parent_email === root.parent_email ? ROOT.name : item.parent_email,
      detail: item,
      children: [],
    };
  };

  const startNode = [ROOT];

  return startNode.concat(
    list.map((item, index) => {
      return node(item, index);
    })
  );
};

export const treeFactory = (list) => {
  const idAttr = "id";
  const parentAttr = "parent_id";
  const childrenAttr = "children";

  const treeList = [];

  const lookup = {};

  list.forEach((obj) => {
    lookup[obj[idAttr]] = obj;
    obj[childrenAttr] = [];
  });

  list.forEach((obj, index) => {
    if (obj[parentAttr] != null) {
      lookup[obj[parentAttr]][childrenAttr].push(obj);
    } else {
      treeList.push(obj);
    }
  });
  return treeList;
};

export const isFloat = (value) => {
  if (!value) {
    return "-";
  }
  if (!isNaN(Number(value)) && value.toString().substring(0, 2) !== "0x") {
    if (value % 1 === 0) {
      return numeral(value).format("0");
    } else {
      return numeral(value).format("0,0.0000");
    }
  } else {
    return value;
  }
};
