import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

import Loader from "components/Loader";
import { httpHeader } from "utils";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Box, Chip, Button, Container, Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataTable from "./DataTableAdmin";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePicker } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";

import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

import UserTab from "pages/Admin/UserTab";
import moment from "moment";

const Home = (props) => {
  axios.defaults.timeout = 600000;

  console.log("프롭스인어드민 :", props);
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  // const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [dataType, setDataType] = React.useState("day");

  const [PanelCreationModal, copen, cclose, cisOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const today = moment().format("YYYY-MM-DD").toString();
  const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD").toString();
  const [selectedDate, setSelectedDate] = React.useState({
    start: yesterday,
    end: today,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");
  const [DataModal, dataModalOpen, dataModalClose, isDataModalOpen] = useModal(
    "root",
    {
      preventScroll: false,
      closeOnOverlayClick: true,
    }
  );

  const [selectedCC, setSelectedCC] = React.useState(null);
  const [panels, setPannel] = React.useState(null);
  const [operators, setOperators] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [CCs, setCCs] = React.useState(null);

  const paneltitleInput = useInput("");
  const panelNumberInput = useInput("");

  const [type, setType] = React.useState("left");

  const handleChangeSelect = (event) => {
    setType(event.target.value);
  };
  const handleRadioChange = (event) => {
    setDataType(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffectOnce(async () => {
    const operator = new Operator(user.id);

    setoperator(operator);
    setcc(props.cc);
    setData(props.panel);
    setUsers(props.operators);
  });

  const handleDataExportModal = (panel) => {
    dataModalOpen();
  };

  React.useEffect(() => {
    if (props.refresh) {
      (async () => {
        console.log("리프레쉬드 인 파워테이블 어드민");
        const operator = new Operator(user.id);
        setoperator(operator);
        setcc(props.cc);
        console.log(props.cc);
        setData(props.panel);
        setUsers(props.operators);
        // setRefresh(false);
      })();
    }
  }, [props.refresh]);
  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <Box sx={{ typography: "body1" }}>
            <TabContext value={value} centered>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  centered
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="분전" value="1" />
                  <Tab label="운영자 계정" value="2" />
                  <Tab label="통계" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Chip
                      sx={{ mb: 2 }}
                      label={props.cc.title}
                      variant="outlined"
                    />
                  </Box>
                  <Box
                    sx={{ mb: 2 }}
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button onClick={copen} variant="outlined">
                      분전 추가
                    </Button>
                  </Box>

                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>분전</TableCell>
                          <TableCell>타입</TableCell>
                          <TableCell align="center">전원</TableCell>
                          <TableCell align="center">삭제</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.panel &&
                          props.panel.map((row, i) => (
                            <TableRow
                              key={row.title + String(i)}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                onClick={() => {
                                  history.push(
                                    `/user/powerdetail/${row.cc_id}/${row.id}`
                                  );
                                }}
                              >
                                {row.title}
                              </TableCell>
                              <TableCell>{row.direction}</TableCell>
                              <TableCell align="center">
                                <Chip
                                  size={"small"}
                                  color={row.switch ? "primary" : "error"}
                                  label={row.switch ? "ON" : "OFF"}
                                  sx={{
                                    backgroundColor: row.switch
                                      ? "#D32E2E"
                                      : "#008000",
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Chip
                                  size={"small"}
                                  color={"error"}
                                  variant={"outlined"}
                                  label={"삭제"}
                                  onClick={async () => {
                                    const confirm =
                                      window.confirm(
                                        "해당 배전을 삭제 하시겠습니까"
                                      );
                                    if (!confirm) {
                                      return;
                                    }
                                    const result = await operator.deletePanel(
                                      row.id
                                    );
                                    alert("삭제 완료");
                                    props.setRefresh(true);
                                    return;
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </TabPanel>
              <TabPanel value="3">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Chip
                      sx={{ mb: 2 }}
                      label={props.cc.title}
                      variant="outlined"
                    />
                  </Box>
                  <Box
                    sx={{ mb: 2 }}
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {" "}
                    <Button
                      onClick={() => {
                        dataModalOpen();
                      }}
                      variant="outlined"
                      mb={1}
                    >
                      엑셀 전환
                    </Button>
                  </Box>
                </Box>
                <DataTable
                  cc={props.cc}
                  panel={props.panel}
                  setPanel={props.setPannel}
                  operators={props.operators}
                  refresh={props.refresh}
                  setRefresh={props.setRefresh}
                ></DataTable>
              </TabPanel>
              <UserTab
                setRefresh={props.setRefresh}
                operators={props.operators}
                cc={props.cc}
              ></UserTab>
            </TabContext>
          </Box>
          <Box></Box>
          <Modal sx={{ zIndex: "99999" }}>
            <Container fixed>
              <Box sx={{}}>
                {/* <Paper
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ p: 2 }}
                > */}
                <Box>
                  <Chip label={"아시아 CC"} />
                </Box>
                <Box>
                  {[
                    {
                      placeholder: t("아이디"),
                      type: "id",
                      value: idInput.value,
                      onChange: idInput.onChange,
                    },
                    {
                      placeholder: t("비밀번호"),
                      type: "password",
                      value: passwordInput.value,
                      onChange: passwordInput.onChange,
                    },
                  ].map((option) => {
                    return (
                      <TextField
                        inputProps={{
                          style: { color: theme.palette.firstGreen },
                        }}
                        sx={{
                          mt: 2,
                          "& label.Mui-focused": {
                            color: theme.palette.firstGreen,
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: theme.palette.firstGreen,
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme.palette.firstGreen,
                            },
                            "&:hover fieldset": {
                              borderColor: theme.palette.firstGreen,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: theme.palette.firstGreen,
                            },
                          },
                        }}
                        fullWidth
                        required
                        key={option.placeholder}
                        size="small"
                        {...option}
                      />
                    );
                  })}
                  <Box mt={3}>
                    <Box mt={3}>
                      <Typography my={2}>할당할 차단기</Typography>
                      {[...new Array(10)].map(() => (
                        <Chip
                          label="Clickable"
                          clickable
                          color="primary"
                          // label="헬로"
                        />
                      ))}
                    </Box>
                    <Button onClick={close} fullWidth>
                      {t("계정생성")}
                    </Button>
                  </Box>
                </Box>
                <Box></Box>
                {/* </Paper> */}
              </Box>
            </Container>
          </Modal>
          <PanelCreationModal>
            <Box>
              <Container>
                <Paper>
                  <Container>
                    <Box>
                      {[
                        {
                          placeholder: t("패널명"),
                          type: "id",
                          value: paneltitleInput.value,
                          onChange: paneltitleInput.onChange,
                        },
                        {
                          placeholder: t("차단기 갯수"),
                          type: "id",
                          value: panelNumberInput.value,
                          onChange: panelNumberInput.onChange,
                        },
                      ].map((option) => {
                        return (
                          <TextField
                            inputProps={{
                              style: { color: theme.palette.firstGreen },
                            }}
                            sx={{
                              mt: 2,
                              "& label.Mui-focused": {
                                color: theme.palette.firstGreen,
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: theme.palette.firstGreen,
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: theme.palette.firstGreen,
                                },
                                "&:hover fieldset": {
                                  borderColor: theme.palette.firstGreen,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: theme.palette.firstGreen,
                                },
                              },
                            }}
                            fullWidth
                            required
                            key={option.placeholder}
                            size="small"
                            {...option}
                          />
                        );
                      })}

                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="demo-simple-select-label">
                          타입
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={type}
                          label="타입"
                          onChange={handleChangeSelect}
                        >
                          <MenuItem value={"left"}>좌 타입</MenuItem>
                          <MenuItem value={"right"}>우 타입</MenuItem>
                          <MenuItem value={"center"}>좌우 타입</MenuItem>
                        </Select>
                      </FormControl>
                      <Box mt={3}>
                        <Button
                          onClick={async () => {
                            const panelInfo = {
                              number: Number(panelNumberInput.value),
                              cc_id: props.cc.id,
                              title: paneltitleInput.value,
                              direction: type,
                            };
                            console.log("ㅁㅁㅁ2 : ", panelInfo);

                            const result = await operator.adminCreatePanel(
                              panelInfo
                            );
                            console.log("ㅁㅁㅁ3 : ", result);
                            if (result) {
                              alert("성공");
                              props.setRefresh(true);
                              cclose();
                              return;
                            } else {
                              alert("실패");
                              cclose();
                              return;
                            }
                          }}
                          fullWidth
                        >
                          {t("패널 생성")}
                        </Button>
                        <Button onClick={cclose} fullWidth>
                          {t("취소")}
                        </Button>
                      </Box>
                    </Box>
                  </Container>
                </Paper>
              </Container>
            </Box>
          </PanelCreationModal>
          <DataModal>
            <Paper sx={{ minHeight: "390px" }}>
              <Box
                direction="column"
                justifyContent="center"
                alignItems="center"
                component={Paper}
                sx={{ height: "35px", width: "100%" }}
              >
                <Container>
                  <Typography variant="subtitle1" align="left">
                    파일 Export
                  </Typography>
                </Container>
              </Box>
              <Container>
                <Box
                  mt={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack
                    direction={{ xs: "column" }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{ width: "100%" }}
                    >
                      일자 선택
                    </Typography>

                    {[
                      {
                        label: "시작일자 선택",
                        value: selectedDate.start,
                        onChange: (value) => {
                          console.log("선택 벨류", value);
                          setSelectedDate((prev) => ({
                            ...prev,
                            start: moment(value)
                              .format("YYYY-MM-DD")
                              .toString(),
                          }));
                        },
                      },
                      {
                        label: "완료일자 선택",
                        value: selectedDate.end,
                        onChange: (value) => {
                          setSelectedDate((prev) => ({
                            ...prev,
                            end: moment(value).format("YYYY-MM-DD").toString(),
                          }));
                        },
                      },
                    ].map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          {/*   </LocalizationProvider><LocalizationProvider dateAdapter={AdapterDateFns}*></React.Fragment>*/}

                          <DatePicker
                            label={v.label}
                            value={v.value}
                            onChange={v.onChange}
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </React.Fragment>
                      );
                    })}
                    <Box
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Typography variant="body2" align="left">
                        데이터 타입
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        <FormControl display="flex" direction="row">
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={dataType}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="day"
                              control={<Radio />}
                              label="일보"
                            />
                            <FormControlLabel
                              value="month"
                              control={<Radio />}
                              label="월보"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  {" "}
                  <Button
                    onClick={() => {
                      console.log(props.cc);
                      // process.env.REACT_APP_HOST
                      axios({
                        url:
                          process.env.REACT_APP_HOST +
                          `/excel?` +
                          new URLSearchParams({
                            startDate: selectedDate.start,
                            endDate: selectedDate.end,
                            type: dataType,
                            cc_id: props.cc.id,
                          }),
                        responseType: "blob",
                        method: "GET",
                        // headers: {
                        //   "Content-Type": "application/pdf",
                        // },
                      }).then((response) => {
                        const blob = response.data;
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                          new Blob([blob])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `circuit_report.xlsx`);

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                      });
                    }}
                    variant="outlined"
                    mb={1}
                  >
                    엑셀 다운로드
                  </Button>
                </Box>
              </Container>
            </Paper>
          </DataModal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
