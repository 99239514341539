import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import Header from "pages/User/Home/Header";
import FirstLayer from "pages/User/Home/FirstLayer";
import SecondLayer from "pages/User/Home/SecondLayer";
import ThirdLayer from "pages/User/Home/ThirdLayer";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import {
  Card,
  Box,
  Chip,
  Button,
  Container,
  Paper,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";
import { useSnackbar } from "notistack";

import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import MobileTimePicker from "@mui/lab/MobileTimePicker";

const CreationModal = (props) => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [timeValue, setTimeValue] = React.useState(
    new Date("2018-01-01T00:00:00.000Z")
  );
  const [selectedChip, setSelectedChip] = React.useState(null);
  const [schedules, setSchedules] = React.useState([]);
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [panel, setPanel] = React.useState(null);
  const [cb, setCB] = React.useState(null);

  const [userAccess, setUserAccess] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);

    const cc = await operator.getCCs();
    setcc(cc.result);

    const paneldata = await operator.getPanels();
    const cbs = paneldata.map((v) => {
      return operator.getCBs(v.id);
    });
    const cbData = await Promise.all(cbs);
    setCB(cbData);
    setPanel(paneldata);
    setUsers(await operator.getUsers());

    const selectedData = cbData.map((v) => {
      return v.map((j) => {
        let copyj = j;
        copyj.selected = false;
        return copyj;
      });
    });
    setSelected(selectedData);

    setData(paneldata);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const operator = new Operator(user.id);
        setoperator(operator);

        const cc = await operator.getCCs();

        setcc(cc.result);

        const paneldata = await operator.getPanels();
        setPanel(paneldata);
        const userData = await operator.getUsers();
        setUsers();

        const cbs = paneldata.map((v) => {
          return operator.getCBs(v.id);
        });
        const cbData = await Promise.all(cbs);
        setCB(cbData);
        console.log("panelCBData", cbData);
        const selectedData = cbData.map((v) => {
          return v.map((j) => {
            let copyj = j;
            copyj.selected = false;
            return copyj;
          });
        });
        setSelected(selectedData);

        setData(paneldata);
        setRefresh(false);
      })();
    }
  }, [refresh]);
  React.useEffect(() => {
    console.log(selected);
  }, [selected]);
  React.useEffect(() => {
    console.log(schedules);
  }, [schedules]);
  const createOperator = async () => {
    const userId = idInput.value;
    const password = passwordInput.value;
    if (password.length < 4) {
      return setDialogMessage(t("비밀번호가 짧습니다(4자이상)"));
    }
    if (userId.length < 4) {
      return setDialogMessage(t("유저아이디가 짧습니다(4자이상)"));
    }
    console.log(selected);

    const body = {
      userId: userId.trim(),
      password: password.trim(),
    };
    // 유저 아이디 체크
    const { data: checkId } = await axios.post(
      `${process.env.REACT_APP_HOST}/user/isExist`,
      body
    );
    if (checkId.result) {
      return setDialogMessage(t("유저아이디가 존재합니다"));
    }

    const operatorBody = {
      operator: {
        account: body.userId,
        password: body.password,
        is_admin: false,
        is_manager: true,
        is_user: true,
        token: "operator",
        cc_id: props.cc.id,
      },
      cc_id: props.cc.id,
    };
    // 계정 생성
    const { data: registerData } = await axios.post(
      `${process.env.REACT_APP_HOST}/admin/operator/create`,
      operatorBody
    );
    if (!registerData.status) {
      return setDialogMessage(t("시스템 에러발생(e:144"));
    }
    const [userInfo] = registerData.result;
    console.log("유저인포", userInfo);
    let thebody = [];
    selected.map((v, i) => {
      selected[i].map((j, t) => {
        if (j.selected) {
          let copyj = j;
          copyj.cc_id = cc.id;
          thebody.push(copyj);
        }
      });
    });
    console.log("thebody", thebody);
    const accessBody = { access: thebody, user_id: userInfo.id };
    console.log("엑세스 바디", accessBody);
    //배전에 대한 유저 엑세스 만들기
    const { data: accessData } = await axios.post(
      `${process.env.REACT_APP_HOST}/operator/useraccess/create`,
      accessBody
    );
    console.log("엑세스데이터", accessData);

    if (accessData.status) {
      setDialogMessage(t("회원추가가 완료되었습니다"));
      props.close();
      props.setRefresh(true);
    } else {
      return setDialogMessage("다시 시도해주세요");
    }
  };
  const params = { refresh, setRefresh, ...data };

  return (
    <React.Fragment>
      {data ? (
        <Container>
          <Box>
            <Paper sx={{ p: 2 }}>
              <Box>
                <Chip label={"스케쥴 생성"} />
              </Box>
              <Box>
                {[
                  {
                    placeholder: t("스케쥴 제목"),
                    type: "id",
                    value: idInput.value,
                    onChange: idInput.onChange,
                  },
                ].map((option) => {
                  return (
                    <TextField
                      inputProps={{
                        style: { color: theme.palette.firstGreen },
                      }}
                      sx={{
                        mt: 2,
                        "& label.Mui-focused": {
                          color: theme.palette.firstGreen,
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: theme.palette.firstGreen,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: theme.palette.firstGreen,
                          },
                          "&:hover fieldset": {
                            borderColor: theme.palette.firstGreen,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: theme.palette.firstGreen,
                          },
                        },
                      }}
                      fullWidth
                      required
                      key={option.placeholder}
                      size="small"
                      {...option}
                    />
                  );
                })}
                <Box mt={3}>
                  <Box mt={3}>
                    <Typography mb={1}>스케쥴을 추가 하세요</Typography>
                    <Paper>
                      <Container sx={{ p: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <MobileTimePicker
                              mt={1}
                              label="스케쥴을 추가할 시간을 고르세요"
                              value={timeValue}
                              onChange={(newValue) => {
                                setTimeValue(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="space-between"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <Chip
                            label="ON"
                            color="error"
                            variant={
                              selectedChip === 1 ? "contained" : "outlined"
                            }
                            onClick={() => setSelectedChip(1)}
                          ></Chip>
                          <Chip
                            label="OFF"
                            color="primary"
                            variant={
                              selectedChip === 0 ? "contained" : "outlined"
                            }
                            onClick={() => setSelectedChip(0)}
                          ></Chip>
                        </Box>
                        <Box></Box>
                        <Button
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="space-between"
                          fullWidth
                          onClick={() => {
                            if (selectedChip === null) {
                              alert("ON 혹은 OFF를 선택해주세요");
                              return;
                            }
                            const time = moment(timeValue)
                              .format("HH:mm")
                              .toString();
                            console.log(time);
                            let stringfyArray = schedules.map((v, i) => {
                              return JSON.stringify(v);
                            });
                            stringfyArray.push(
                              JSON.stringify({ type: selectedChip, time })
                            );
                            const uniqueArr = stringfyArray.filter(
                              (element, index) => {
                                return stringfyArray.indexOf(element) === index;
                              }
                            );
                            const jsonArray = uniqueArr.map((v) => {
                              return JSON.parse(v);
                            });

                            setSchedules(jsonArray);
                            setRefresh(true);
                          }}
                        >
                          {t("추가")}
                        </Button>
                      </Container>
                    </Paper>
                    <Typography mt={1}>추가될 스케쥴</Typography>
                    <Paper sx={{ mb: 1 }}>
                      <Container sx={{ p: 1 }}>
                        {schedules.map((v) => (
                          <Chip
                            color={v.type ? "error" : "primary"}
                            label={
                              String(v.time) +
                              " " +
                              String(v.type ? "ON" : "OFF")
                            }
                          ></Chip>
                        ))}
                      </Container>
                    </Paper>
                  </Box>
                  <Button
                    sx={{ mt: 1 }}
                    onClick={async () => {
                      console.log("들어옴?");
                      const { result } = await operator.createSchedulegroup(
                        idInput.value
                      );
                      let group_id;
                      if (result) {
                        group_id = result.id;
                      } else {
                        alert("시스템 에러 (1606)");
                        return;
                      }
                      const result2 = await operator.createSchedule(
                        schedules,
                        group_id
                      );
                      if (!result2) {
                        alert("시스템 에러 (1607)");
                        props.close();

                        return;
                      }
                      setSchedules([]);
                      props.setRefresh(true);
                      alert("성공");
                      props.close();
                    }}
                    fullWidth
                  >
                    {t("스케쥴 추가")}
                  </Button>
                  <Button
                    sx={{ mt: 1 }}
                    onClick={() => {
                      setSchedules([]);
                      props.close();
                    }}
                    fullWidth
                  >
                    {t("취소")}
                  </Button>
                </Box>
              </Box>
              <Box></Box>
            </Paper>
          </Box>
        </Container>
      ) : (
        <Page>
          <Loader />
        </Page>
      )}
    </React.Fragment>
  );
};

export default CreationModal;
