import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";

import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Circuit from "resources/images/circuit.png";
import Power from "resources/images/power.png";

import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import { httpHeader } from "utils";
import Graph from "pages/User/Graph.js";
import { Operator } from "classes";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ko } from "date-fns/locale";

import {
  Box,
  Chip,
  Typography,
  Container,
  Grid,
  CardContent,
  Modal,
  Paper,
  Button,
  ButtonBase,
  CardActions,
  Card,
  Link,
  TextField,
} from "@mui/material";
import { useModal } from "react-hooks-use-modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const DateDayDetail = ({ match }) => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [selectedcb, setSelectedcb] = React.useState(null);
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [selectedPanel, setSelectedPanel] = React.useState(null);
  const { cc_id } = match.params;
  const { panel_id } = match.params;
  const { cb_id } = match.params;
  const [isLoading, setIsLoading] = React.useState(true);
  const [dropen, setDROpen] = React.useState(true);
  const toggle = () => setDROpen(!dropen);
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [cbDataData, setCBDataData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [refreshInterval, setRefreshInterval] = React.useState(null);

  const registerInterval = () => {
    if (refreshInterval) {
      return;
    }
    console.log("인터벌 스타티드");

    const interval = setInterval(async function () {
      setRefresh(true);
      console.log("인터벌 ing");
    }, 30 * 1000);
    setRefreshInterval(interval);
  };
  const unregisterInterval = () => {
    clearInterval(refreshInterval);
    console.log("클리어드 인터벌");
    setRefreshInterval(null);
  };

  const handleChangePage = (event, newPage) => {
    console.log(event, newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "name", label: "날짜", minWidth: 170 },
    { id: "code", label: "Watt", minWidth: 100 },
    {
      id: "population",
      label: "Volt",
      minWidth: 170,
      format: (value) => value.toLocaleString("en-US"),
    },
    { id: "amp", label: "amp", minWidth: 100 },
  ];
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  const rows = [
    createData("India", "IN", 1324171354, 3287263),
    createData("China", "CN", 1403500365, 9596961),
    createData("Italy", "IT", 60483973, 301340),
    createData("United States", "US", 327167434, 9833520),
    createData("Canada", "CA", 37602103, 9984670),
    createData("Australia", "AU", 25475400, 7692024),
    createData("Germany", "DE", 83019200, 357578),
    createData("Ireland", "IE", 4857000, 70273),
    createData("Mexico", "MX", 126577691, 1972550),
    createData("Japan", "JP", 126317000, 377973),
    createData("France", "FR", 67022000, 640679),
    createData("United Kingdom", "GB", 67545757, 242495),
    createData("Russia", "RU", 146793744, 17098246),
    createData("Nigeria", "NG", 200962417, 923768),
    createData("Brazil", "BR", 210147125, 8515767),
  ];
  function checkStateChange(checkState, cb_id) {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      setTimeout(async function () {
        const cbState = await operator.getCBStatusSwitch(cb_id);
        // console.log("씨비 스테이트", cbState);
        setIsLoading(false);
        if (!cbState.result) {
          resolve(false);
          return;
        }
        if (cbState.result.switch === checkState) {
          resolve(true);
          return;
        } else {
          resolve(false);
          return;
        }
      }, 5000);
    });
  }

  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);

    if (user.is_admin) {
      const cc = await operator.getCC(cc_id);
      setcc(cc.result);
    } else {
      const cc = await operator.getCC(cc_id);
      setcc(cc.result);
    }

    const cb = await operator.getCBs(panel_id);
    const sorted = cb.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    let panel = await operator.getPanelbyPanelId(panel_id);
    console.log("패널", panel);

    setSelectedPanel(panel);

    setIsLoading(false);
    setData(sorted);
    registerInterval();
    return () => unregisterInterval();
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const operator = new Operator(user.id);
        setoperator(operator);

        if (user.is_admin) {
          const cc = await operator.getCC(cc_id);
          setcc(cc.result);
        } else {
          const cc = await operator.getCC(cc_id);
          setcc(cc.result);
        }

        operator.getUsers();

        const cb = await operator.getCBs(panel_id);
        const sorted = cb.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }
          if (a.index < b.index) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        let totalamp = 0;
        cb.map((v) => {
          totalamp += v.amp;
        });
        console.log(totalamp);

        let panel = await operator.getPanelbyPanelId(panel_id);
        // let [selectedPanel] = panel.filter((v) => v.id === Number(panel_id));
        panel.amp = totalamp;
        console.log("패널", panel);

        setSelectedPanel(panel);
        setRefresh(false);
        setData(sorted);
      })();
    }
  }, [refresh]);
  React.useEffect(() => {}, [selectedcb]);

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {/* <Header /> */}

      {data ? (
        <React.Fragment>
          <Card sx={{ width: "100vw", height: "44px" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "100%", textAlign: "center" }}
            >
              <ArrowBackIcon
                onClick={() => {
                  unregisterInterval();
                  if (user.is_admin) return history.goBack();
                  if (user.is_manager) return history.goBack();
                  if (user.is_user) return history.goBack();
                }}
                sx={{ m: 1 }}
              />
              <Typography sx={{ mr: 4 }}>
                {cc.title} {selectedPanel.title}
              </Typography>

              <div></div>
            </Box>
          </Card>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
            }}
          >
            {/* <Container maxWidth={false}> */}
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Container>
                  <Paper sx={{ p: 1 }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <DateRange
                        locale={ko}
                        editableDateInputs={true}
                        onChange={(item) => {
                          console.log("셀력션 아이템", item.selection);
                          return setState([item.selection]);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        onClick={async () => {
                          if (!state[0].endDate) {
                            alert("날짜를 선택해주세요");
                            return;
                          }
                          const range = state;
                          const cbDataData = await operator.getCBData(
                            cb_id,
                            range
                          );
                          const sortedCBData = cbDataData.result.sort(
                            (a, b) => {
                              if (a.time < b.time) {
                                return -1;
                              }
                              if (a.time > b.time) {
                                return 1;
                              }
                              return 0;
                            }
                          );
                          setCBDataData(sortedCBData);
                        }}
                        fullWidth
                      >
                        검색
                      </Button>
                    </Box>
                  </Paper>
                </Container>
              </Grid>
            </Grid>
            {/* </Container> */}
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
            }}
          >
            <Container maxWidth={false}>
              {/* <Grid container spacing={1}>
                <Grid item> */}
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table size="small">
                    <TableHead
                      stickyheader="true"
                      style={{ backgroundColor: "white" }}
                    >
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cbDataData &&
                        cbDataData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                // key={row.code}
                              >
                                <TableCell>
                                  {moment(row.time).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {(row.watt / row.count).toFixed(1)}
                                </TableCell>
                                <TableCell>
                                  {(row.volt / row.count).toFixed(1)}
                                </TableCell>
                                <TableCell>
                                  {(row.amp / row.count).toFixed(1)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={cbDataData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
              {/* </Grid>
                테이블
              </Grid> */}
            </Container>
          </Box>

          <Modal></Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default DateDayDetail;
