import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import { Box, Chip, Button, Container, Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";

import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";
import moment from "moment";
import UserTab from "pages/Admin/UserTab";

const Home = (props) => {
  console.log("프롭스인어드민 :", props);

  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);

  // const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  const history = useHistory();

  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });

  const [PanelCreationModal, copen, cclose, cisOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");

  const paneltitleInput = useInput("");
  const panelNumberInput = useInput("");

  const [type, setType] = React.useState("left");

  const handleChangeSelect = (event) => {
    setType(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    console.log("프롭스", props);
    setoperator(operator);
    setcc(props.cc);
    setData(props.panel);
    setUsers(props.operators);
  });

  React.useEffect(() => {
    if (props.refresh) {
      (async () => {
        console.log("리프레쉬드 인 파워테이블 어드민");
        const operator = new Operator(user.id);
        setoperator(operator);
        setcc(props.cc);
        console.log(props.cc);
        setData(props.panel);
        setUsers(props.operators);
        props.setRefresh(false);
      })();
    }
  }, [props.refresh]);
  return (
    <Paper>
      {data ? (
        <React.Fragment>
          <Box sx={{ typography: "body1" }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                {/* <Chip
                      sx={{ mb: 2 }}
                      label={props.cc.title}
                      variant="outlined"
                    /> */}
              </Box>

              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>분전</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.panel &&
                      props.panel.map((row, i) => (
                        <TableRow
                          key={row.title + String(i)}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => {
                              history.push(
                                `/admin/datacbselect/${row.cc_id}/${row.id}`
                              );
                            }}
                          >
                            {row.title}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box></Box>
          <Modal sx={{ zIndex: "99999" }}>
            <Container fixed>
              <Box sx={{}}>
                {/* <Paper
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ p: 2 }}
                > */}
                <Box>
                  <Chip label={"아시아 CC"} />
                </Box>
                <Box>
                  {[
                    {
                      placeholder: t("아이디"),
                      type: "id",
                      value: idInput.value,
                      onChange: idInput.onChange,
                    },
                    {
                      placeholder: t("비밀번호"),
                      type: "password",
                      value: passwordInput.value,
                      onChange: passwordInput.onChange,
                    },
                  ].map((option) => {
                    return (
                      <TextField
                        inputProps={{
                          style: { color: theme.palette.firstGreen },
                        }}
                        sx={{
                          mt: 2,
                          "& label.Mui-focused": {
                            color: theme.palette.firstGreen,
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: theme.palette.firstGreen,
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme.palette.firstGreen,
                            },
                            "&:hover fieldset": {
                              borderColor: theme.palette.firstGreen,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: theme.palette.firstGreen,
                            },
                          },
                        }}
                        fullWidth
                        required
                        key={option.placeholder}
                        size="small"
                        {...option}
                      />
                    );
                  })}
                  <Box mt={3}>
                    <Box mt={3}>
                      <Typography my={2}>할당할 차단기</Typography>
                      {[...new Array(10)].map(() => (
                        <Chip
                          label="Clickable"
                          clickable
                          color="primary"
                          // label="헬로"
                        />
                      ))}
                    </Box>
                    <Button onClick={close} fullWidth>
                      {t("계정생성")}
                    </Button>
                  </Box>
                </Box>
                <Box></Box>
                {/* </Paper> */}
              </Box>
            </Container>
          </Modal>

          <PanelCreationModal>
            <Box>
              <Container>
                <Paper>
                  <Container>
                    <Box>
                      {[
                        {
                          placeholder: t("패널명"),
                          type: "id",
                          value: paneltitleInput.value,
                          onChange: paneltitleInput.onChange,
                        },
                        {
                          placeholder: t("차단기 갯수"),
                          type: "id",
                          value: panelNumberInput.value,
                          onChange: panelNumberInput.onChange,
                        },
                      ].map((option) => {
                        return (
                          <TextField
                            inputProps={{
                              style: { color: theme.palette.firstGreen },
                            }}
                            sx={{
                              mt: 2,
                              "& label.Mui-focused": {
                                color: theme.palette.firstGreen,
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: theme.palette.firstGreen,
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: theme.palette.firstGreen,
                                },
                                "&:hover fieldset": {
                                  borderColor: theme.palette.firstGreen,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: theme.palette.firstGreen,
                                },
                              },
                            }}
                            fullWidth
                            required
                            key={option.placeholder}
                            size="small"
                            {...option}
                          />
                        );
                      })}

                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="demo-simple-select-label">
                          타입
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={type}
                          label="타입"
                          onChange={handleChangeSelect}
                        >
                          <MenuItem value={"left"}>좌 타입</MenuItem>
                          <MenuItem value={"right"}>우 타입</MenuItem>
                          <MenuItem value={"center"}>좌우 타입</MenuItem>
                        </Select>
                      </FormControl>
                      <Box mt={3}>
                        <Button
                          onClick={async () => {
                            const panelInfo = {
                              number: Number(panelNumberInput.value),
                              cc_id: props.cc.id,
                              title: paneltitleInput.value,
                              direction: type,
                            };
                            console.log("ㅁㅁㅁ2 : ", panelInfo);

                            const result = await operator.adminCreatePanel(
                              panelInfo
                            );
                            console.log("ㅁㅁㅁ3 : ", result);
                            if (result) {
                              alert("성공");
                              props.setRefresh(true);
                              cclose();
                              return;
                            } else {
                              alert("실패");
                              cclose();
                              return;
                            }
                          }}
                          fullWidth
                        >
                          {t("패널 생성")}
                        </Button>
                        <Button onClick={cclose} fullWidth>
                          {t("취소")}
                        </Button>
                      </Box>
                    </Box>
                  </Container>
                </Paper>
              </Container>
            </Box>
          </PanelCreationModal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Paper>
  );
};

export default Home;
