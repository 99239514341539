import axios from "axios";
export function Operator(userId) {
  this.userId = userId;
  this.getCCs = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_HOST + `/operator/cc/${userId}`
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getCC = async (cc_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/cc`,
        { cc_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getAdminCCs = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_HOST + `/admin/cc`
      );
      console.log("CC데이터", data);
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.createUser = async (user, cc_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/user/create`,
        { user }
      );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.adminDeleteOpereator = async (user_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/admin/operator/delete`,
        { user_id }
      );
      if (!data.status) {
        throw new Error("유저 삭제 실패");
      }
      // const { data: useraccess } = await axios.post(
      //   process.env.REACT_APP_HOST + `/operator/useraccess/delete`,
      //   { user_id }
      // );
      return data.status;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.adminCreatePanel = async (panelInfo) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/admin/panel/create`,
        { panelInfo }
      );
      // if (!data.status) {
      //   throw new Error("유저 삭제 실패");
      // }
      console.log("데이터 소스", data.status);
      return data.status;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.deleteUser = async (user_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/user/delete`,
        { user_id }
      );
      if (!data.status) {
        throw new Error("유저 삭제 실패");
      }
      const { data: useraccess } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/useraccess/delete`,
        { user_id }
      );
      return useraccess.status;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getUsers = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_HOST + `/operator/users/${userId}`
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getUserAccess = async (user_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/useraccess/get`,
        { user_id }
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getCBByUserAccess = async (ua) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/useraccess/cb/get`,
        { ua }
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.createUserAccess = async (user_id, access) => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_HOST + `/operator/useraccess/create`
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.createAdminCC = async (title) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/admin/cc/create`,
        { title }
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getUserPanels = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_HOST + `/user/panel/${userId}`
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getAdminPanels = async (cc_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/admin/panel`,
        { cc_id }
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getAdminOperators = async (cc_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/admin/operators`,
        { cc_id }
      );
      console.log("오퍼레이터", data.result);
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getAdminPanels = async (cc_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/admin/panel`,
        { cc_id }
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getPanels = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_HOST + `/operator/panel/${userId}`
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getPanelbyPanelId = async (panel_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/panel/get`,
        { panel_id }
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.setPanelSwitch = async (panel_id, power) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/panel/switch`,
        { panel_id, power }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getCBs = async (panelId) => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_HOST + `/operator/cb/${panelId}`
      );
      return data.result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.setCBSwitch = async (cb_id, power) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/cb/switch`,
        { cb_id, power }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getCBStatusSwitch = async (cb_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/operator/cb/switch/get`,
        { cb_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.createSchedulegroup = async (title) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/schedulegroup/create`,
        { title }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getSchedulegroup = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/schedulegroup/get`,
        {}
      );
      console.log("스케쥴 그룹 데이터", data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.deleteSchedulegroup = async (group_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/schedulegroup/delete`,
        { group_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.createSchedule = async (scheduleObjs, group_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/schedule/create`,
        { scheduleObjs, group_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.deleteSchedule = async (group_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/schedule/delete`,
        { group_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getSchedule = async (group_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/schedule/get`,
        { group_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getSchedules = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/schedules/get`,
        {}
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.createCBschedule = async (cbScheduleObjs) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cbschedule/create`,
        { cbScheduleObjs }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.deleteCBschedule = async (cb_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cbschedule/delete`,
        { cb_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getCBschedule = async (cb_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cbschedule/get`,
        { cb_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getCBschedules = async (cb_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cbschedules/get`,
        {}
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.updateAlias = async (aliasData) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/alias/update`,
        { aliasData }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.deletePanel = async (id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/panel/delete`,
        { id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.deleteCB = async (id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cb/delete`,
        { id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.createCB = async (panel_id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cb/create`,
        { panel_id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.createWarning = async (warningObj) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/warning/create`,
        { warningObj }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.updateWarning = async (id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/warning/update`,
        { id }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getWarnings = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/warning/get`,
        {}
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.countWarnings = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/warning/count`,
        {}
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getCBData = async (cb_id, range) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cbData/get`,
        { cb_id, range }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getCBDataMonth = async (cb_id, range) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cbDataMonth/get`,
        { cb_id, range }
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  this.getCBStatus = async (cb_id, range) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/cbstatus/get`,
        { cb_id, range }
      );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  this.getUsersCCid = async (userid) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + `/userccid/get`,
        { userid }
      );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
}
