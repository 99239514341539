import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import Header from "pages/User/Home/Header";
import FirstLayer from "pages/User/Home/FirstLayer";
import SecondLayer from "pages/User/Home/SecondLayer";
import ThirdLayer from "pages/User/Home/ThirdLayer";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import {
  Card,
  Box,
  Chip,
  Button,
  Container,
  Paper,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Typography from "@mui/material/Typography";

import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";
import { useSnackbar } from "notistack";

import ScheduleCreationModal from "./ScheduleCreationModal";
import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

const UserTab = () => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();

  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [CreateModal, copen, cclose, cisOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [scheduleGroup, setScheduleGroup] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [schedules, setSchedules] = React.useState(null);
  const [panel, setPanel] = React.useState(null);
  const [chipArray, setChipArray] = React.useState([]);

  const [userAccess, setUserAccess] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);
    const scheduleGroupData = await operator.getSchedulegroup();
    setScheduleGroup(scheduleGroupData.result);
    console.log(scheduleGroupData.result);

    const scheduleData = await operator.getSchedules();
    setSchedules(scheduleData.result);
    console.log(scheduleData.result);

    setData("ㅁ");
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const operator = new Operator(user.id);
        setoperator(operator);
        const scheduleGroupData = await operator.getSchedulegroup();
        setScheduleGroup(scheduleGroupData.result);
        console.log(scheduleGroupData.result);

        const scheduleData = await operator.getSchedules();
        setSchedules(scheduleData.result);
        console.log(scheduleData.result);
        setRefresh(false);
        setData("ㅁ");
      })();
    }
  }, [refresh]);

  const UserAccessModal = () => {
    return (
      <React.Fragment>
        <Container>
          <Box sx={{ width: "90vw" }}>
            <Paper
              // display="flex"
              // flexDirection="column"
              // justifyContent="center"
              // alignItems="center"
              sx={{ p: 2 }}
            >
              <Box>
                <Chip label={"아시아 CC"} />
              </Box>
              <Box>
                <Container>
                  <Typography my={2}>할당된 차단기</Typography>

                  {/* 맵 두번 돌려서  1. 배전 이름 쓰기, 2. 배전 인덱스에 관한 cb애들 뽑기 */}
                  <Box mt={3}>
                    {panel &&
                      panel.map((v) => {
                        const Title = <Typography mb={1}>{} 배전</Typography>;
                        const chips = userAccess.map((j) => {
                          if (j.panel_id === v.id) {
                            return (
                              <Chip
                                sx={{ mb: 2 }}
                                color="primary"
                                label={j.alias + "번"}
                              ></Chip>
                            );
                          }
                        });
                        return (
                          <React.Fragment>
                            {Title}
                            {chips.length ? (
                              chips.map((v) => v)
                            ) : (
                              <Typography mb={1}> -</Typography>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </Box>
                </Container>
                <Button onClick={close} fullWidth>
                  {t("확인")}
                </Button>
              </Box>
              <Box></Box>
            </Paper>
          </Box>
        </Container>
      </React.Fragment>
    );
  };

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <TabPanel value="2">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <Chip
                  sx={{ mb: 2 }}
                  label={"등록된 스케쥴"}
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{ mb: 2 }}
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button onClick={copen} variant="outlined">
                  스케쥴 추가
                </Button>
              </Box>

              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        스케쥴 번호
                      </TableCell>

                      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        스케쥴명
                      </TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        예약
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scheduleGroup &&
                      scheduleGroup.map((row, i) => (
                        <TableRow
                          key={row.title + String(i)}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">{row.id}번</TableCell>

                          <TableCell align="center">
                            {row.title}
                            <Box sx={{ display: "block" }}>
                              <Chip
                                clickable
                                variant="outlined"
                                color="error"
                                size="small"
                                label="삭제"
                                onClick={async () => {
                                  const deletion =
                                    window.confirm("스케쥴을 삭제하시겠습니까");
                                  if (deletion) {
                                    const status =
                                      await operator.deleteSchedule(row.id);
                                    console.log("삭제", row.group_id);

                                    const status2 =
                                      await operator.deleteSchedulegroup(
                                        row.id
                                      );

                                    console.log("삭제", status2, status);
                                    if (status2 && status) {
                                      enqueueSnackbar("성공", {
                                        variant: "success",
                                      });
                                      setRefresh(true);
                                      return;
                                    }
                                    enqueueSnackbar("다시 시도해주세요", {
                                      variant: "error",
                                    });

                                    setRefresh(true);
                                  }
                                }}
                              ></Chip>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            {schedules &&
                              schedules.map((t, j) => {
                                if (t.group_id === row.id) {
                                  //   chipsarray.push(t);
                                  return (
                                    <Chip
                                      clickable
                                      sx={{ margin: "0.5px" }}
                                      key={t.time + t.type + String(j)}
                                      variant="contained"
                                      color={t.type ? "primary" : "error"}
                                      size="small"
                                      label={
                                        t.time +
                                        " " +
                                        String(t.type ? "ON" : "OFF")
                                      }
                                    ></Chip>
                                  );
                                }
                              })}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>
          <Modal>
            <UserAccessModal></UserAccessModal>
          </Modal>
          <CreateModal>
            <ScheduleCreationModal
              setRefresh={setRefresh}
              close={cclose}
            ></ScheduleCreationModal>
          </CreateModal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default UserTab;
