import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Select, Typography, TextField, Button, Paper, MenuItem, useMediaQuery } from "@mui/material";
import { httpHeader } from "utils";
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";
import AdminPage from "components/AdminPage";
import axios from "axios";

const Notice = () => {
  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const [select, setSelect] = React.useState("공지");

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
  const smView = useMediaQuery(theme.breakpoints.down("sm"));

  const onSubmit = async (e) => {
    e.preventDefault();
    const body = {
      title: title,
      content: content,
      type: select,
    };
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/notice/post", body, httpHeader());
    if (data.status) {
      enqueueSnackbar(t("등록이 완료되었습니다."), { variant: "info" });
    } else {
      enqueueSnackbar(t("등록에 실패하였습니다."), { variant: "error" });
    }
  };

  return (
    <AdminPage header>
      <Paper style={{ paddingLeft: smView ? 0 : 240 }} className={classes.paper}>
        <div className={classes.noticeContainer}>
          <form className={classes.noticeSize} onSubmit={onSubmit}>
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                <Typography className={classes.titleText}>{t("제목")}</Typography>
              </div>

              <div className={classes.selectContainer}>
                <Select
                  className={classes.select}
                  value={select}
                  label="태그"
                  onChange={(e) => setSelect(e.target.value)}
                >
                  <MenuItem value="공지">{t("공지")}</MenuItem>
                  <MenuItem value="이벤트">{t("이벤트")}</MenuItem>
                  <MenuItem value="긴급">{t("긴급")}</MenuItem>
                </Select>
              </div>
            </div>

            <div className={classes.titleTextContainer}>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t("제목을 입력해주세요.")}
                className={classes.titleText}
              ></TextField>
            </div>

            <div className={classes.contentTitle}>
              <Typography className={classes.contentTitleText}>{t("내용")}</Typography>
            </div>

            <div className={classes.contentTextField}>
              <TextField
                placeholder="내용을 입력해주세요."
                value={content}
                onChange={(e) => setContent(e.target.value)}
                multiline={true}
                inputProps={{
                  style: {
                    height: 450,
                    overflow: "auto",
                  },
                }}
                className={classes.contentText}
              ></TextField>
            </div>

            <div className={classes.buttonContainer}>
              <Button type="submit" className={classes.button} variant="contained" color="primary">
                {t("등록")}
              </Button>
            </div>
          </form>
        </div>
      </Paper>
    </AdminPage>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "100%",
      height: "95vh",
      overflow: "auto",
      padding: 100,
    },
    noticeContainer: {
      width: 750,
      height: "100%",
      paddingLeft: 50,
      paddingRight: 200,
    },
    noticeSize: {
      width: "100%",
      height: 700,
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
    },
    titleText: {
      fontWeight: "700",
      width: "100%",
    },
    selectContainer: {
      display: "inline-block",
      marginBottom: 15,
    },
    select: {
      height: 40,
    },
    titleTextContainer: {
      marginBottom: 20,
    },

    contentTitle: {
      marginBottom: 15,
    },
    contentTitleText: {
      fontWeight: "700",
    },
    contentText: {
      width: "100%",
    },
    contentTextField: {
      marginBottom: 15,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      fontWeight: "700",
    },
  };
});

export default Notice;
