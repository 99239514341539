import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Stack, Grid, Tab, Tabs, Box } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { styled, useTheme } from '@mui/material/styles';


import GlobalContext from "context/GlobalContext";
import ReactCountryFlag from "react-country-flag";

import { useTranslation } from "react-i18next";


import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';






const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const LinkTab = (props) => {
  return <Tab style={{ color: "white" }} {...props} />;
};

const languages = [
  {
    languageCode: "ko",
    value: "kr",
  },
  {
    languageCode: "en",
    value: "us",
  },
  {
    languageCode: "zh",
    value: "cn",
  },
  {
    languageCode: "ja",
    value: "jp",
  },
  {
    languageCode: "km",
    value: "kh",
  },
];

const LanguageSelection = (props) => {
  const history = useHistory();
  const theme = useTheme();

  const { language, setLanguage, setIsLogin } = React.useContext(GlobalContext);
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <Box>
      {/* <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              반토 어드민
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

        </Box>
      </Box> */}



      {/* <Grid className={classes.grid} container direction="row" justifyContent="space-around" alignItems="center" mt={3}>
        <Stack direction="row" spacing={2} mt={2}>
          {languages.map(({ value, languageCode }, index) => (
            <Button
              sx={{
                "&:hover": {
                  border: 0,
                },
              }}
              key={index + languageCode}
              variant={languageCode !== language ? "outlined" : "selected"}
              className={languageCode !== language ? classes.button : classes.selectedButton}
              onClick={() => {
                setLanguage(languageCode);
              }}
            >
              <ReactCountryFlag svg countryCode={value.toUpperCase()} />
            </Button>
          ))}
        </Stack>
      </Grid>
      <Box pt={1} className={classes.box} sx={{ flexGrow: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          // scrollButtons

          allowScrollButtonsMobile
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
            "& div.MuiTabs-scroller": {
              "& .MuiTabs-flexContainer": {
                justifyContent: "center",
              },
            },
          }}
        >
          {t("패키지")} {t("수당")} {t("출금")} {t("히스토리")} {t("마케팅")} {t("문의")}
          {[
            { label: "대여기록", link: "/" },
            { label: "정산", link: "/distribution" },
            { label: "출금", link: "/" },
            { label: "히스토리", link: "/" },
            { label: "마케팅", link: "/" },
            { label: "비밀", link: "/" },
          ].map((item, index) => {
            return (
              <LinkTab
                key={item.label + index}
                label={t(item.label)}
                onClick={(event) => {

                  history.push(item.link)
                }}
              />
            );
          })}
          <LinkTab
            label={t("로그아웃")}
            onClick={() => {
              setIsLogin(false);
              sessionStorage.removeItem("token");
            }}
          />
        </Tabs>
      </Box> */}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.metalGreen,
    minWidth: "0",
    width: "2.8em",
    height: "2.2em",
    border: "none",
  },
  selectedButton: {
    color: theme.palette.lightGrey,
    minWidth: "0",
    width: "2.8em",
    height: "2.2em",
    backgroundColor: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.white,
      opacity: 0.75,
    },
  },
  grid: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.metalGreen,
  },
  box: {
    backgroundColor: theme.metalGreen,
  },
}));

export default LanguageSelection;
