import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { errorFactory } from "utils";
import GlobalContext from "context/GlobalContext";
import { useHistory } from "react-router";

const GuestForm = ({ children, params }) => {
  const { setIsLogin, setUser, setDialogMessage } =
    React.useContext(GlobalContext);
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();

    switch (params.page) {
      case "login": {
        const body = {
          email: params.email.trim(),
          password: params.password.trim(),
        };

        let { data } = await axios.post(
          `${process.env.REACT_APP_HOST}/login`,
          body
        );
        console.log("데이터 스테터스", data.status);
        if (data.status) {
          sessionStorage.setItem("token", data.result.user.account);
          console.log("게스트폼", data.result.user);
          setUser(data.result.user);
          setIsLogin(true);
          if (data.result.user.is_admin) {
            console.log("1");
            history.replace("/admin/powertable");
          } else if (data.result.user.is_operator) {
            console.log("2");
            history.replace("/user/powertable");
          } else {
            console.log("3");
            history.replace("/");
          }
        } else {
          return setDialogMessage(errorFactory(data.error));
        }

        break;
      }

      case "register": {
        if (!params.isConfirm) {
          return setDialogMessage(t("이메일 인증이 완료되지 않았습니다."));
        }
        if (params.password !== params.passwordCheck) {
          return setDialogMessage(t("두 비밀번호가 맞지 않습니다."));
        }
        if (params.password.length < 6) {
          return setDialogMessage(t("비밀번호가 짧습니다."));
        }
        if (params.wPassword !== params.wPasswordCheck) {
          return setDialogMessage(t("두 출금 비밀번호가 맞지 않습니다."));
        }
        if (params.wPassword.length < 6) {
          return setDialogMessage(t("출금 비밀번호가 짧습니다."));
        }

        const body = {
          email: params.email.trim(),
          password: params.password.trim(),
          wPassword: params.wPassword.trim(),
          recommend: params.recommend.trim(),
          token: params.token.trim(),
          id: params.isConfirm,
        };

        const { data } = await axios.post(
          `${process.env.REACT_APP_HOST}/register`,
          body
        );
        if (data.status) {
          setDialogMessage(
            t("회원가입이 완료되었습니다. 입력하신 정보로 로그인해주세요.")
          );
          history.push("/");
        } else {
          return setDialogMessage(errorFactory(data.error));
        }

        break;
      }

      case "forgot": {
        if (!params.isConfirm) {
          return setDialogMessage(t("이메일 인증이 완료되지 않았습니다."));
        }
        if (params.password !== params.passwordCheck) {
          return setDialogMessage(t("두 비밀번호가 맞지 않습니다."));
        }
        if (params.password.length < 6) {
          return setDialogMessage(t("비밀번호가 짧습니다."));
        }

        const body = {
          email: params.email.trim(),
          password: params.password.trim(),
          token: params.token.trim(),
          id: params.isConfirm,
        };

        const { data } = await axios.post(
          `${process.env.REACT_APP_HOST}/forgot`,
          body
        );
        if (data.status) {
          setDialogMessage(
            t("비밀번호가 변경되었습니다. 입력하신 정보로 로그인해주세요.")
          );
          history.push("/");
        } else {
          return setDialogMessage(errorFactory(data.error));
        }

        break;
      }
      default:
        break;
    }
  };

  return React.cloneElement(children, {
    onSubmit,
  });
};

export default GuestForm;
