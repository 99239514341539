import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tree from "react-d3-tree";
import { useTheme } from "@mui/material/styles";

import GlobalContext from "context/GlobalContext";

import { useEffectOnce } from "react-use";
import { emailMasking } from "utils";

const nodeSvgShape = {
  shape: "none",
  shapeProps: {
    width: 15,
    height: 15,
    x: -7.5,
    y: -7.5,
  },
};

const Hierarchy = ({ tree, getTree }) => {
  const shouldRecenterTreeRef = React.useRef(true);
  const [treeTranslate, setTreeTranslate] = React.useState({ x: 0, y: 0 });
  const treeContainerRef = React.useRef(null);
  const classes = useStyles();
  const { user } = React.useContext(GlobalContext);
  const theme = useTheme();

  useEffectOnce(() => {
    if (!tree) {
      getTree();
    }
  });

  useEffect(() => {
    if (tree) {
      if (treeContainerRef.current && shouldRecenterTreeRef.current) {
        shouldRecenterTreeRef.current = false;
        const dimensions = treeContainerRef.current.getBoundingClientRect();

        setTreeTranslate({
          x: dimensions.width / 2,
          y: dimensions.height / 4,
        });
      }
    }
  }, [tree]);
  const NodeLabel = ({ nodeDatum }) => {
    const isMe = nodeDatum.name === user.email;
    return (
      <svg x={-110} width="220px" height="100px" viewBox="0 0 220 100" version="1.1">
        <g stroke="none" strokeWidth="1" fill={"none"} fillRule="evenodd">
          <rect
            stroke={isMe ? theme.palette.firstGreen : "black"}
            strokeWidth="5"
            fill="#FFFFFF"
            x="2.5"
            y="2.5"
            width="215"
            height="80"
          ></rect>
          <text fontSize="15" fontWeight="bold" textAnchor="middle" fill="#000000">
            <tspan x="110" y="30">
              {isMe ? nodeDatum.name : emailMasking(nodeDatum.name)}
            </tspan>
            {isMe && (
              <tspan x="110" y="65">
                (ME)
              </tspan>
            )}
          </text>
        </g>
      </svg>
    );
  };

  return (
    tree && (
      <Box ref={treeContainerRef} className={classes.wrapper}>
        <Tree
          nodeSize={{ x: 260, y: 200 }}
          nodeSvgShape={nodeSvgShape}
          enableLegacyTransitions={true}
          pathFunc="step"
          data={tree}
          translate={treeTranslate}
          orientation={"vertical"}
          allowForeignObjects
          renderCustomNodeElement={NodeLabel}
        />
      </Box>
    )
  );
};

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      border: `2px solid ${theme.palette.fourthGrey}`,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.backGround,
    },
  };
});

export default Hierarchy;
