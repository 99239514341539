import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomDialog = ({ isOpen, setMessage, title = "알림", children }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={isOpen}
      onClose={() => setMessage(null)}
    >
      <DialogTitle sx={{ wordBreak: "break-all" }}>{t(title)}</DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex" }}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => setMessage(null)} color="primary">
          {t("닫기")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
