import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import {
  Box,
  Chip,
  Button,
  Container,
  Paper,
  TextField,
  Card,
  Link,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Typography from "@mui/material/Typography";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";

import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

import SchedulerTable from "pages/Admin/SchedulerTable";

const Home = () => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [value, setValue] = React.useState("2");
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const operatorAPI = async () => {
    const operator = new Operator(user.id);
    setoperator(operator);
    const cc = await operator.getCCs();
    setcc(cc.result);
    const panel = await operator.getPanels();
    setData(panel);
    setUsers(await operator.getUsers());
  };

  const userAPI = async () => {
    const operator = new Operator(user.id);
    setoperator(operator);
    const cc = await operator.getCCs();

    setcc(cc.result);
    const panel = await operator.getUserPanels();
    setData(panel);
    setUsers(await operator.getUsers());
  };
  useEffectOnce(async () => {
    if (user.is_manager) {
      await operatorAPI();
    } else {
      await userAPI();
    }
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        if (user.is_manager) {
          await operatorAPI();
        } else {
          await userAPI();
        }
        setRefresh(false);
      })();
    }
  }, [refresh]);

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <Card sx={{ width: "100vw", height: "44px" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "100%", textAlign: "center" }}
            >
              <ArrowBackIcon
                onClick={() => {
                  if (user.is_admin) return history.goBack();
                  if (user.is_manager) return history.goBack();
                  if (user.is_user) return history.goBack();
                }}
                sx={{ m: 1 }}
              />
              <Typography sx={{ mr: 4 }}>스케쥴 생성</Typography>

              <div></div>
            </Box>
          </Card>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value} centered>
              <SchedulerTable></SchedulerTable>
            </TabContext>
          </Box>
          <Box></Box>
          <Modal>
            <Container>
              <Box sx={{ width: "90vw" }}>
                <Paper sx={{ p: 2 }}>
                  <Box>
                    <Chip label={"아시아 CC"} />
                  </Box>
                  <Box>
                    {[
                      {
                        placeholder: t("아이디"),
                        type: "id",
                        value: idInput.value,
                        onChange: idInput.onChange,
                      },
                      {
                        placeholder: t("비밀번호"),
                        type: "password",
                        value: passwordInput.value,
                        onChange: passwordInput.onChange,
                      },
                    ].map((option) => {
                      return (
                        <TextField
                          inputProps={{
                            style: { color: theme.palette.firstGreen },
                          }}
                          sx={{
                            mt: 2,
                            "& label.Mui-focused": {
                              color: theme.palette.firstGreen,
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: theme.palette.firstGreen,
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                              "&:hover fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                            },
                          }}
                          fullWidth
                          required
                          key={option.placeholder}
                          size="small"
                          {...option}
                        />
                      );
                    })}
                    <Box mt={3}>
                      <Box mt={3}></Box>
                      <Button onClick={close} fullWidth>
                        {t("계정생성")}
                      </Button>
                    </Box>
                  </Box>
                  <Box></Box>
                </Paper>
              </Box>
            </Container>
          </Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
