import React from "react";
import GlobalContext from "context/GlobalContext";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Routes from "Routes";
import { SnackbarProvider } from "notistack";
import useDialog from "hooks/useDialog";
import CustomDialog from "components/CustomDialog";

const App = () => {
  const [isLogin, setIsLogin] = React.useState(
    sessionStorage.getItem("token") ? true : false
  );
  const [initialize, setInitialize] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const [language, setLanguage] = React.useState("ko");
  const [adminTables, setAdminTables] = React.useState(null);
  const [adminFocused, setAdminFocused] = React.useState(null);
  const [setDialogMessage, dialogProps] = useDialog();

  const [network, setNetwork] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const contextValue = {
    user,
    setUser,
    isLogin,
    setIsLogin,
    drawerIsOpen,
    setDrawerIsOpen,
    initialize,
    setInitialize,
    adminTables,
    setAdminTables,
    language,
    setLanguage,
    setDialogMessage,
    dialogProps,
    network,
    setNetwork,
    selectedAddress,
    setSelectedAddress,
    adminFocused,
    setAdminFocused,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      <CustomDialog setDialogMessage={setDialogMessage} {...dialogProps}>
        {dialogProps.message}
      </CustomDialog>
      <HelmetProvider>
        <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
          <div id="modal-div">
            <Router>
              <Routes />
            </Router>
          </div>
        </SnackbarProvider>
      </HelmetProvider>
    </GlobalContext.Provider>
  );
};

export default App;
