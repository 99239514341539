import React from "react";
import { makeStyles } from "@mui/styles";
import { useEffectOnce } from "react-use";
import axios from "axios";
import { useTranslation } from "react-i18next";

import GlobalContext from "context/GlobalContext";
import AdminPage from "components/AdminPage";
import AdminHierarchy from "pages/Admin/Home/AdminHierarchy";
import { httpHeader, nodeFactory, nodeFactoryCommunity, treeFactory } from "utils";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AdminHome = () => {
  const { user } = React.useContext(GlobalContext);
  const theme = useTheme();
  const smView = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = React.useState(null);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffectOnce(async () => {
    const { data } = await axios.post(process.env.REACT_APP_HOST + `/admin/home`, null, httpHeader());
    const nodeRecommend = nodeFactory(data.result.recommendTree, data.result.recommendTree[0]);
    const sortedRecommend = treeFactory(nodeRecommend);

    const nodeCommunity = nodeFactoryCommunity(data.result.communityTree);
    const sortedCommunity = treeFactory(nodeCommunity);

    setData({ communityTree: sortedCommunity, recommendTree: sortedRecommend });
  });
  return (
    <AdminPage title="Admin Home" header>
      <Paper style={{ paddingLeft: smView ? 0 : 240, height: "100%" }} className={classes.paper}>
        {data && (
          <React.Fragment>
            <Box sx={{ p: 2, height: "50%", minHeight: 220 }}>
              <Typography variant="h3">{t("전체 추천트리")}</Typography>
              <AdminHierarchy tree={data.recommendTree} />
            </Box>
            <Box sx={{ p: 2, height: "50%", minHeight: 220 }}>
              <Typography variant="h3">{t("전체 스폰서트리")}</Typography>
              <AdminHierarchy tree={data.communityTree} />
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </AdminPage>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default AdminHome;
