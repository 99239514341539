import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";

import Page from "components/Page";
import GlobalContext from "context/GlobalContext";
import useInput from "hooks/useInput";
import GuestForm from "../GuestForm";
import { errorFactory } from "utils";
import theme from "styles/theme";
import Intro from "components/Intro";

const EXPIRE_TIME = 1000 * 30;

const Login = () => {
  const classes = useStyles();
  const { setDialogMessage } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  const emailInput = useInput("");
  const tokenInput = useInput("");
  const passwordInput = useInput("");
  const passwordCheckInput = useInput("");

  //출금비밀번호
  const wPasswordInput = useInput("");
  const wPasswordCheckInput = useInput("");

  const recommendInput = useInput("");

  const [isConfirm, setIsConfirm] = React.useState(false);
  const [time, setTime] = React.useState(0);

  const params = {
    email: emailInput.value,
    recommend: recommendInput.value,
    password: passwordInput.value,
    passwordCheck: passwordCheckInput.value,
    wPassword: wPasswordInput.value,
    wPasswordCheck: wPasswordCheckInput.value,
    token: tokenInput.value,
    page: "register",
    isConfirm,
  };

  const sendToken = async () => {
    const now = new Date().getTime();
    if (time + EXPIRE_TIME > now) {
      return setDialogMessage(new Date(time + EXPIRE_TIME - now).getSeconds() + t("초 뒤에 재전송이 가능합니다."));
    }

    const body = { email: emailInput.value, register: true };
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/auth/send", body);
    if (data.status) {
      setIsConfirm(data.result);
      setDialogMessage(t("인증번호가 전송되었습니다. 5분안에 인증번호를 입력해주세요."));
      setTime(new Date().getTime());
    } else {
      setDialogMessage(errorFactory(data.error));
    }
  };

  return (
    <Page title={t("회원가입")}>
      <Container maxWidth="xs" className={classes.root}>
        <Intro style={{ marginBottom: "20px" }} />
        <GuestForm params={params}>
          <form style={{ marginBottom: "20px" }} className={classes.form}>
            <Button onClick={sendToken} className={classes.sendButton} variant="contained" fullWidth>
              {t("인증번호 전송")}
            </Button>
            {[
              { placeholder: t("이메일"), type: "email", value: emailInput.value, onChange: emailInput.onChange },
              {
                placeholder: t("인증 번호"),
                type: "text",
                value: tokenInput.value,
                onChange: tokenInput.onChange,
              },
              {
                placeholder: t("비밀번호"),
                type: "password",
                value: passwordInput.value,
                onChange: passwordInput.onChange,
              },
              {
                placeholder: t("비밀번호 확인"),
                type: "password",
                value: passwordCheckInput.value,
                onChange: passwordCheckInput.onChange,
              },
              {
                placeholder: t("출금 비밀번호"),
                type: "password",
                value: wPasswordInput.value,
                onChange: wPasswordInput.onChange,
              },
              {
                placeholder: t("출금 비밀번호 확인"),
                type: "password",
                value: wPasswordCheckInput.value,
                onChange: wPasswordCheckInput.onChange,
              },
              {
                placeholder: t("추천인 이메일"),
                type: "email",
                value: recommendInput.value,
                onChange: recommendInput.onChange,
              },
            ].map((option, index) => {
              return (
                <TextField
                  inputProps={{ style: { color: theme.palette.firstGreen } }}
                  sx={{
                    mb: 1,

                    "& label.Mui-focused": {
                      color: theme.palette.firstGreen,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: theme.palette.firstGreen,
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.firstGreen,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.firstGreen,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.firstGreen,
                      },
                    },
                  }}
                  fullWidth
                  required
                  key={option.placeholder}
                  size="small"
                  {...option}
                />
              );
            })}
            <Box mt={3}>
              <Button className={classes.selectedButton} type="submit" fullWidth>
                {t("회원가입")}
              </Button>
            </Box>
          </form>
        </GuestForm>
      </Container>
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
  form: {
    position: "relative",
  },
  sendButton: {
    zIndex: 10,
    maxWidth: 120,
    position: "absolute",
    right: 4,
    top: 4,
    height: 32,
    background:
      "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    color: theme.palette.lightGrey,
    backgroundColor: theme.palette.firstGreen,
  },
  root: {
    paddingTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.palette.lightGrey,
    color: theme.palette.darkGrey,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
  },
  selectedButton: {
    background:
      "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    color: theme.palette.lightGrey,
    backgroundColor: theme.palette.firstGreen,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
    marginBottom: "8px",
  },
}));

export default Login;
