import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import Header from "pages/User/Home/Header";
import FirstLayer from "pages/User/Home/FirstLayer";
import SecondLayer from "pages/User/Home/SecondLayer";
import ThirdLayer from "pages/User/Home/ThirdLayer";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import { Box, Chip, Button, Container, Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";
import { useSnackbar } from "notistack";

import UserCreationModal from "./CreationModal";
import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

const UserTab = () => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();

  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [CreateModal, copen, cclose, cisOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [panel, setPanel] = React.useState(null);
  const [cb, setCB] = React.useState(null);

  const [userAccess, setUserAccess] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);

    const cc = await operator.getCCs();
    setcc(cc.result);
    const cbData = await operator.getCBs();
    setCB(cbData);
    const paneldata = await operator.getPanels();
    console.log("paneldata", paneldata);
    setPanel(paneldata);
    setUsers(await operator.getUsers());

    setData(paneldata);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        console.log("왜 리프레시 안하니");
        const operator = new Operator(user.id);
        setoperator(operator);

        const cc = await operator.getCCs();
        setcc(cc.result);
        const cbData = await operator.getCBs();
        setCB(cbData);
        const paneldata = await operator.getPanels();
        console.log("paneldata", paneldata);
        setPanel(paneldata);
        setUsers(await operator.getUsers());

        setData(paneldata);
        setRefresh(false);
      })();
    }
  }, [refresh]);

  const UserAccessModal = () => {
    return (
      <React.Fragment>
        <Container>
          <Box sx={{ width: "90vw" }}>
            <Paper
              // display="flex"
              // flexDirection="column"
              // justifyContent="center"
              // alignItems="center"
              sx={{ p: 2 }}
            >
              <Box>
                <Chip label={"아시아 CC"} />
              </Box>
              <Box>
                <Container>
                  <Typography my={2}>할당된 차단기</Typography>

                  {/* 맵 두번 돌려서  1. 배전 이름 쓰기, 2. 배전 인덱스에 관한 cb애들 뽑기 */}
                  <Box mt={3}>
                    {panel &&
                      panel.map((v) => {
                        const Title = (
                          <Typography mb={1}>{v.title} 배전</Typography>
                        );
                        const chips = userAccess.map((j) => {
                          if (j.panel_id === v.id) {
                            return (
                              <Chip
                                sx={{ mb: 2 }}
                                color="primary"
                                label={j.alias + "번"}
                              ></Chip>
                            );
                          }
                        });
                        return (
                          <React.Fragment>
                            {Title}
                            {chips.length ? (
                              chips.map((v) => v)
                            ) : (
                              <Typography mb={1}> -</Typography>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </Box>
                </Container>
                <Button onClick={close} fullWidth>
                  {t("확인")}
                </Button>
              </Box>
              <Box></Box>
            </Paper>
          </Box>
        </Container>
      </React.Fragment>
    );
  };

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <TabPanel value="2">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <Chip
                  sx={{ mb: 2 }}
                  label={"아시아나 CC 분전"}
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{ mb: 2 }}
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button onClick={copen} variant="outlined">
                  계정 추가
                </Button>
              </Box>

              {data && (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>아이디</TableCell>
                        <TableCell>삭제</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users &&
                        users.map((row) => (
                          <TableRow
                            key={row.account}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              onClick={async () => {
                                const ua = await operator.getUserAccess(row.id);
                                const uatocb = await operator.getCBByUserAccess(
                                  ua
                                );
                                setUserAccess(uatocb);
                                open();
                              }}
                            >
                              {row.account}
                            </TableCell>
                            <TableCell>
                              <Chip
                                clickable
                                variant="outlined"
                                color="error"
                                size="small"
                                label="삭제"
                                onClick={async () => {
                                  const deletion =
                                    window.confirm("유저를 삭제하시겠습니까");
                                  if (deletion) {
                                    const status = await operator.deleteUser(
                                      row.id
                                    );

                                    if (status) {
                                      enqueueSnackbar("성공", {
                                        variant: "success",
                                      });
                                      setRefresh(true);
                                      return;
                                    }
                                    enqueueSnackbar("다시 시도해주세요", {
                                      variant: "error",
                                    });

                                    setRefresh(true);
                                  }
                                }}
                              ></Chip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </TabPanel>
          <Modal>
            <UserAccessModal></UserAccessModal>
          </Modal>
          <CreateModal>
            {users && (
              <UserCreationModal
                setRefresh={setRefresh}
                close={cclose}
              ></UserCreationModal>
            )}
          </CreateModal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default UserTab;
