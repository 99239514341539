import React, { useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import axios from "axios";
import firebase from "firebaseConfig";
import { initAuth, getIdToken, signOut } from 'Auth';

import GuestLogin from "pages/Guest/Login";
import GuestRegister from "pages/Guest/Register";
import GuestForgot from "pages/Guest/Forgot";

import UserHome from "pages/User/Home";
import Admin from "pages/Admin";
import AdminNotice from "pages/Admin/AdminNotice";
import GlobalContext from "context/GlobalContext";
import AdminHome from "pages/Admin/Home";
import { httpHeader } from "utils";

//admin
//로그인 /admin AdminLogin
//메뉴  /admin/menu AdminMenu
//cc분전테이블 /admin/powertable AdminPowerTable
//분전 상세      admin/powerdetail
//스케쥴   /admin/schedule AdminSchedule
//차단기 번호 변경  /admin/alias AdminPowerAlias

//operator
//사용자 계정 테이블  /operator/users OperatorUserTable
//cc 운영자 계정 /operator  OperatorLogin

//user
//로그인 /user UserLogin
//분전 테이블(cc 선택창 없음)/user/powertable UserPowerTable
//분전 상세      /powerdetail powerdetail
//차단기 설정 테이블  /config

import AdminLogin from "pages/Admin/Login";
import AdminMenu from "pages/Admin/Menu";
import AdminPowerTable from "pages/Admin/PowerTable";
import AdminSchedule from "pages/Admin/Schedule";
import AdminPowerAlias from "pages/Admin/PowerAlias";
import AdminPowerDetail from "pages/Admin/PowerDetail";
import AdminCBAdd from "pages/Admin/Add";
import AdminWarnings from "pages/Admin/Warnings";
import AdminData from "pages/Admin/Data";
import AdminDataDayDetail from "pages/Admin/DataDayDetail";
import AdminDataQuarterDetail from "pages/Admin/DataQuarterDetail";

import AdminDataCBSelect from "pages/Admin/DataCBSelect";
import AdminSchedulePower from "pages/Admin/SchedulePower";
import AdminScheduleCreation from "pages/Admin/ScheduleCreation";
import AdminAlias from "pages/Admin/Alias";

import OperatorLogin from "pages/Operator/Login";
import OperatorUserTable from "pages/Operator/UserTable";

import UserLogin from "pages/Guest/Login";
import UserPowerTable from "pages/User/PowerTable";
import UserPowerDetail from "pages/User/PowerDetail";
import Config from "pages/User/Config";

const GuestRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={UserLogin} />
      {/* <Route exact path="/admin" component={AdminLogin} />
      <Route exact path="/operator" component={OperatorLogin} />
      <Route exact path="/user" component={UserLogin} /> */}

      <Route exact path="/register" component={GuestRegister} />
      <Route exact path="/forgot" component={GuestForgot} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

const UserRoutes = () => {
  const { adminTables, user } = React.useContext(GlobalContext);
  return (
    <Switch>
      {user.is_admin && (
        <React.Fragment>
          <Route exact path="/" component={AdminMenu} />
          <Route exact path="/admin/menu" component={AdminMenu} />
          <Route exact path="/admin/powertable" component={AdminPowerTable} />
          <Route exact path="/admin/powerdetail" component={AdminPowerDetail} />
          <Route exact path="/admin/schedule" component={AdminSchedule} />
          <Route exact path="/admin/alias" component={AdminPowerAlias} />
          <Route exact path="/admin/data" component={AdminData} />
          <Route
            exact
            path="/admin/datacbselect/:cc_id/:panel_id"
            component={AdminDataCBSelect}
          />

          <Route
            exact
            path="/admin/datadaydetail/:cc_id/:panel_id/:cb_id"
            component={AdminDataDayDetail}
          />
          <Route
            exact
            path="/admin/dataquarterdetail/:cc_id/:panel_id/:cb_id"
            component={AdminDataQuarterDetail}
          />
          <Route
            exact
            path="/admin/add/:cc_id/:panel_id"
            component={AdminCBAdd}
          />
          <Route exact path="/warnings" component={AdminWarnings} />

          <Route
            exact
            path="/user/powerdetail/:cc_id/:panel_id"
            component={UserPowerDetail}
          />
          <Route
            exact
            path="/schedule/:cc_id/:panel_id"
            component={AdminSchedulePower}
          />
          <Route exact path="/scheduler" component={AdminScheduleCreation} />
          <Route exact path="/alias/:cc_id/:panel_id" component={AdminAlias} />
          {/* {adminTables &&
            adminTables.map((item) => {
              return <Route key={item.table} exact path={"/admin/" + item.table} component={Admin} />;
            })} */}
        </React.Fragment>
      )
      }
      {
        user.is_operator && (
          <React.Fragment>
            <Route
              exact
              path="/user/powerdetail/:cc_id/:panel_id"
              component={UserPowerDetail}
            />
            <Route exact path="/operator/users" component={OperatorUserTable} />
          </React.Fragment>
        )
      }

      <Route exact path="/" component={UserPowerTable} />
      <Route exact path="/user/powertable" component={UserPowerTable} />
      <Route
        exact
        path="/user/powerdetail/:cc_id/:panel_id"
        component={UserPowerDetail}
      />
      <Route exact path="/user/config" component={Config} />

      <Redirect from="*" to="/" />
    </Switch >
  );
};

const AppRouter = () => {
  const {
    isLogin,
    setIsLogin,
    user,
    setUser,
    initialize,
    setInitialize,
    setAdminTables,
  } = React.useContext(GlobalContext);

  const getUser = useCallback(() => {
    // alert("유저", user)
    if (isLogin) {
      (async () => {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_HOST}/user/info`,
            {},
            httpHeader()
          );
          console.log("데이터 유저", data.user);
          setUser(data.user);
          if (data.user.is_admin === "Y") {
            // const {
            //   data: { result },
            // } = await axios.post(process.env.REACT_APP_HOST + "/admin/tables", null, httpHeader());
            // setAdminTables(result);
          }
          return data.user;
        } catch (e) {
          console.log(e);
          if (e.message === "Request failed with status code 401") {
            setIsLogin(false);
            setUser(null);
            sessionStorage.removeItem("token");
            return "expired";
          }
        } finally {
          setInitialize(true);
        }
      })();
    } else {
      setInitialize(true);
    }
  }, [isLogin, setInitialize, setIsLogin, setUser, setAdminTables]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return initialize ? (
    isLogin && user ? (
      <UserRoutes />
    ) : (
      <GuestRoutes />
    )
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default AppRouter;
