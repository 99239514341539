import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Paper,
  TableHead,
  Button,
  TableFooter,
  TablePagination,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import GlobalContext from "context/GlobalContext";
import { useSnackbar } from "notistack";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

// const rows = [
//     createData('Cupcake', 305, 3.7),
//     createData('Donut', 452, 25.0),
//     createData('Eclair', 262, 16.0),
//     createData('Frozen yoghurt', 159, 6.0),
//     createData('Gingerbread', 356, 16.0),
//     createData('Honeycomb', 408, 3.2),
//     createData('Ice cream sandwich', 237, 9.0),
//     createData('Jelly Bean', 375, 0.0),
//     createData('KitKat', 518, 26.0),
//     createData('Lollipop', 392, 0.2),
//     createData('Marshmallow', 318, 0),
//     createData('Nougat', 360, 19.0),
//     createData('Oreo', 437, 18.0)
// ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },

  tableCell: {
    padding: "0px",
    whiteSpace: "nowrap",
  },
});

export default function LivePreviewExample() {
  const classes = useStyles2();
  const { setDialogMessage } = React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState(null);
  const [total, setTotal] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const { data } = await axios.post(
        process.env.REACT_APP_HOST + "/admin/getRents",
        { page, rowsPerPage }
      );
      console.log(data);
      if (data.code !== 200) {
        alert(data.msg);
        return;
      }

      setRows((prev) => {
        if (prev === null) {
          return data.data;
        }
        return [...prev, ...data.data];
      });
      // setRows(data.data)
      // setCursor(data.data[data.data.length - 1].rentalTimeStamp)
      // console.log("커서", data.data[data.data.length - 1].rentalTimeStamp)
      setTotal(data.total);
    })();
  }, [page, rowsPerPage]);

  console.log("로우스", rows);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, rows && rows.length - page * rowsPerPage);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 대여 시간/ 상태/ 대여스토어/ 대여 스테이션/대여슬롯/ 배터리 /  반납시간/ 반납 스테이션 / 대여슬롯 /유저 정보(전화번호) /기본결제 금액/ 추가 결제 금액/  전액 환불 / 일부 환불

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} align="center">
                대여시간
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                상태
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                대여SID
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                대여슬롯
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                대여BID/대여ID
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                반납시간
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                반납SID
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                반납슬롯
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                설정
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                유저정보
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                기본결제
              </TableCell>

              <TableCell
                className={classes.tableCell}
                sx={{
                  "& .MuiTableCell-alignCenter": {
                    backgroundColor: "yellowgreen",
                  },
                }}
                align="center"
              >
                추가결제
              </TableCell>
              {/* <TableCell align="center">반납시간</TableCell>
                            <TableCell align="center">반납시간</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              (rowsPerPage > 0
                ? rows &&
                  rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows && rows
              ).map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    padding="2px"
                    // component="th" scope="row"
                    align="center"
                  >
                    {moment(row.rentalTimeStamp).format("MM-D(dd) h:mm:ss a")}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.status}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.rentalStationId}
                    <p>{row.storeName}</p>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.returnSlotNum}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.powerBankId}
                    <p>{row.rentId}</p>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.returnTimeStamp
                      ? moment(row.returnTimeStamp).format(
                          "MM-DD(dd) h:mm:ss a"
                        )
                      : ""}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.returnStationId}
                    <p>{row.return_storeName}</p>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.returnSlotNum}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className="m-1"
                      onClick={async () => {
                        setDialogMessage("안녕1");
                        // const message = "환불 하시겠습니까"
                        // if (!window.confirm(message)) {
                        //     return
                        // }
                        // const { data } = await axios.post(process.env.REACT_APP_HOST + "/admin/refund", { id: row.basePurchaseId, reason: "관리자 취소" })
                        // if (data.code !== 200) {
                        //     window.alert("환불 실패")
                        //     return
                        // }
                        // window.location.reload();

                        // return
                      }}
                    >
                      <p>설정</p>
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.phoneNumber}
                    <p>{row.userId}</p>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ padding: "4px 4px" }}
                    align="center"
                  >
                    {!row.refund_price && (
                      <>
                        <p>{row.price}</p>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="m-1"
                          onClick={async () => {
                            const message = "환불 하시겠습니까";
                            if (!window.confirm(message)) {
                              return;
                            }
                            const { data } = await axios.post(
                              process.env.REACT_APP_HOST + "/admin/refund",
                              { id: row.basePurchaseId, reason: "관리자 취소" }
                            );
                            if (data.code !== 200) {
                              window.alert("환불 실패");
                              return;
                            }
                            window.location.reload();

                            return;
                          }}
                        >
                          <p>환불</p>
                        </Button>
                      </>
                    )}
                    {row.refund_price && <p>환불({row.refund_price})</p>}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {!row.extra_refund_price && row.extra_price && (
                      <>
                        <p>{row.extra_price}</p>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="m-1"
                          onClick={async () => {
                            const message = "환불 하시겠습니까";
                            if (!window.confirm(message)) {
                              return;
                            }
                            const { data } = await axios.post(
                              process.env.REACT_APP_HOST + "/admin/refund",
                              { id: row.extraPurchaseId, reason: "관리자 취소" }
                            );
                            if (data.code !== 200) {
                              window.alert("환불 실패");
                              return;
                            }
                            window.location.reload();

                            return;
                          }}
                        >
                          <p>환불</p>
                        </Button>
                      </>
                    )}
                    {row.extra_refund_price && <p>환불({row.extra_price})</p>}
                  </TableCell>
                </TableRow>
              ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: 100 }]}
                colSpan={3}
                count={total && total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
