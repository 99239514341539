import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";

import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Circuit from "resources/images/circuit.png";
import Power from "resources/images/power.png";

import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import { httpHeader } from "utils";
import Graph from "pages/User/Graph.js";
import { Operator } from "classes";
import { useSnackbar } from "notistack";

import {
  Box,
  Typography,
  Container,
  Grid,
  Modal,
  Paper,
  Button,
  ButtonBase,
  Card,
  Link,
} from "@mui/material";
import { useModal } from "react-hooks-use-modal";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Home = ({ match }) => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [selectedcb, setSelectedcb] = React.useState(null);
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [selectedPanel, setSelectedPanel] = React.useState(null);
  const { cc_id } = match.params;
  const { panel_id } = match.params;
  const [refreshInterval, setRefreshInterval] = React.useState(null);

  const registerInterval = () => {
    if (refreshInterval) {
      return;
    }
    const interval = setInterval(async function () {
      setRefresh(true);
    }, 30 * 1000);
    setRefreshInterval(interval);
  };
  const unregisterInterval = () => {
    clearInterval(refreshInterval);
    setRefreshInterval(null);
  };

  const rightCircuit = (cbobj, c) => {
    return (
      <Paper
        flexDirection="row"
        alignContent="center"
        display="flex"
        sx={{ height: "100%" }}
      >
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{ height: "84px" }}
        >
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>

          <Grid
            xs={2}
            sx={{
              border: "solid",
              padding: "0px",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundImage: `url(${Circuit})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    setSelectedcb(cbobj);
                    open();
                  }}
                  variant="contained"
                  color={cbobj.switch ? "primary" : "error"}
                >
                  {cbobj.switch ? "ON" : "OFF"}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={2}
            sx={{ border: "solid" }}
            display="flex"
            flexDirection="column"
            alignContent="center"
          >
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.alias}번</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.amp}A</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    );
  };
  const leftCircuit = (cbobj, c) => {
    return (
      <Paper
        flexDirection="row"
        alignContent="center"
        display="flex"
        sx={{ height: "100%" }}
      >
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{
            padding: "0px",
            height: "85px",
          }}
        >
          <Grid
            xs={2}
            sx={{ border: "solid" }}
            display="flex"
            flexDirection="column"
            alignContent="center"
          >
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.alias}번</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.amp}A</Typography>
            </Box>
          </Grid>

          <Grid
            xs={2}
            sx={{
              border: "solid",
              padding: "0px",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundImage: `url(${Circuit})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    setSelectedcb(cbobj);
                    open();
                  }}
                  variant="contained"
                  color={cbobj.switch ? "primary" : "error"}
                >
                  {cbobj.switch ? "ON" : "OFF"}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
        </Grid>
      </Paper>
    );
  };
  const centerCircuit = (cbobj) => {
    return (
      <Paper
        flexDirection="row"
        alignContent="center"
        display="flex"
        sx={{ height: "100%" }}
      >
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{
            padding: "0px",
            height: "85px",
          }}
        >
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid
            xs={2}
            sx={{ border: "solid" }}
            display="flex"
            flexDirection="column"
            alignContent="center"
          >
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.alias}번</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.amp}A</Typography>
            </Box>
          </Grid>

          <Grid
            xs={2}
            sx={{
              border: "solid",
              padding: "0px",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundImage: `url(${Circuit})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    setSelectedcb(cbobj);
                    open();
                  }}
                  variant="contained"
                  color={cbobj.switch ? "primary" : "error"}
                >
                  {cbobj.switch ? "ON" : "OFF"}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
        </Grid>
      </Paper>
    );
  };

  const powerDrawing = (power) => {
    const powerSwitch = (power) => {
      return (
        <Paper
          flexDirection="row"
          alignContent="center"
          display="flex"
          sx={{ height: "100%" }}
        >
          <Grid
            container
            flexDirection="row"
            alignContent="center"
            display="flex"
            justifyContent="center"
            sx={{}}
          >
            <Grid xs={2} sx={{}}></Grid>
            <Grid xs={1} sx={{}} item></Grid>
            <Grid
              xs={2}
              sx={{}}
              container
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.amp}A</Typography>
              </Box>
              <Box
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.amp}A</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="center"
                alignContent="center"
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.amp}A</Typography>
              </Box>
            </Grid>
            <Grid xs={3} sx={{ padding: "0px" }}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundImage: `url(${Power})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={async () => {
                      const isOn = selectedPanel.switch;
                      const dialog = isOn
                        ? "전원을 끄시겠습니까"
                        : "전원을 키시겠습니까";

                      const confirm = window.confirm(
                        `${selectedPanel.title} ${dialog} `
                      );
                      if (confirm) {
                        const { status: a } = await operator.setPanelSwitch(
                          panel_id,
                          !isOn
                        );

                        if (a) {
                          enqueueSnackbar("성공", { variant: "success" });
                          setRefresh(true);
                          setRefresh(false);

                          // window.location.reload(false);
                        } else {
                          enqueueSnackbar(a.error, { variant: "error" });
                          // window.location.reload(false);
                          setRefresh(true);
                          setRefresh(false);
                        }
                      }
                    }}
                    color={power.switch ? "primary" : "error"}
                  >
                    {power.switch ? "ON" : "OFF"}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid xs={1} sx={{}}></Grid>
            <Grid xs={1} sx={{}}></Grid>
            <Grid xs={2} sx={{}} item></Grid>
          </Grid>
        </Paper>
      );
    };

    let circuitSwitch = (type) => {
      console.log("타입", type);
      if (type === "left") {
        return (
          data &&
          data.map((v, i) => {
            return leftCircuit(v, "c");
          })
        );
      } else if (type === "right") {
        return (
          data &&
          data.map((v, i) => {
            return rightCircuit(v, "c");
          })
        );
      } else {
        return (
          data &&
          data.map((v, i) => {
            if (i % 2 === 0) {
              return leftCircuit(v, "c");
            } else {
              return rightCircuit(v, "c");
            }
          })
        );
      }
    };
    return (
      <React.Fragment>
        {user.is_admin === 0 ? "" : powerSwitch(selectedPanel)}
        {circuitSwitch(selectedPanel.direction)}
      </React.Fragment>
    );
  };

  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);

    if (user.is_admin) {
      const cc = await operator.getCC(cc_id);
      setcc(cc.result);
    } else {
      const cc = await operator.getCCs();
      console.log("어드민 cc", cc);
      setcc(cc.result);
    }
    operator.getUsers();

    const cb = await operator.getCBs(panel_id);
    const sorted = cb.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    let totalamp = 0;
    cb.map((v) => {
      totalamp += v.amp;
    });
    console.log(totalamp);

    let panel = await operator.getPanels();
    let [selectedPanel] = panel.filter((v) => v.id === Number(panel_id));
    selectedPanel.amp = totalamp;
    setSelectedPanel(selectedPanel);

    setData(sorted);
    registerInterval();

    return () => unregisterInterval();
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const operator = new Operator(user.id);
        setoperator(operator);

        if (user.is_admin) {
          const cc = await operator.getCC(cc_id);
          setcc(cc.result);
        } else {
          const cc = await operator.getCCs();
          setcc(cc.result);
        }

        operator.getUsers();

        const cb = await operator.getCBs(panel_id);
        const sorted = cb.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }
          if (a.index < b.index) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        let totalamp = 0;
        cb.map((v) => {
          totalamp += v.amp;
        });
        console.log(totalamp);

        let panel = await operator.getPanels();
        let [selectedPanel] = panel.filter((v) => v.id === Number(panel_id));
        selectedPanel.amp = totalamp;
        setSelectedPanel(selectedPanel);

        setData(sorted);
      })();
    }
  }, [refresh]);

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {/* <Header /> */}

      {data ? (
        <React.Fragment>
          <Card
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100vw", height: "44px" }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "100%", textAlign: "center" }}
            >
              <ArrowBackIcon
                onClick={() => {
                  unregisterInterval();
                  if (user.is_admin) return history.push(`/admin/powertable`);
                  if (user.is_manager) return history.push(`/user/powertable`);
                  if (user.is_user) return history.push(`/user/powertable`);
                }}
                sx={{ m: 1 }}
              />
              <Typography sx={{ mr: 4 }}>
                {cc.title} {selectedPanel.title}
              </Typography>

              <div></div>
            </Box>
          </Card>

          <Card sx={{ width: "100vw", height: "44px" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{ height: "100%", textAlign: "center" }}
            >
              <Link href={`/schedule/${cc_id}/${panel_id}`} underline={"none"}>
                예약 설정
              </Link>
              <Link
                href={`/alias/${cc_id}/${panel_id}`}
                underline="none"
                sx={{}}
              >
                차단기 번호 설정
              </Link>
            </Box>
          </Card>
          <Box>{powerDrawing(selectedPanel, "center")}</Box>

          <Modal>
            <Box sx={{ width: "90vw" }}>
              <Paper
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{}}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ m: 1 }}
                  >
                    {selectedcb && selectedcb.alias}번 차단기
                  </Typography>
                </Box>

                {selectedcb && <Box>{centerCircuit(selectedcb)}</Box>}
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ p: 1, mx: 8 }}
                >
                  스위치
                </Typography>

                <Paper sx={{ p: 1, mb: 8, mx: 8 }}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Button
                      onClick={async () => {
                        const dialog = "전원을 키시겠습니까";

                        const confirm = window.confirm(
                          `${selectedcb.alias}차단기 ${dialog} `
                        );
                        if (confirm) {
                          const { status: a, error } =
                            await operator.setCBSwitch(selectedcb.id, 1);
                          if (a) {
                            enqueueSnackbar("성공", { variant: "success" });
                            let changed = selectedcb;
                            changed.switch = true;
                            setSelectedcb(changed);
                            setRefresh(true);
                            setRefresh(false);
                          } else {
                            enqueueSnackbar(error, { variant: "error" });
                            setRefresh(true);
                            setRefresh(false);
                          }
                        }
                      }}
                    >
                      on
                    </Button>
                    <Button
                      onClick={async () => {
                        const dialog = "전원을 끄시겠습니까";

                        const confirm = window.confirm(
                          `${selectedcb.alias}번 차단기 ${dialog} `
                        );

                        if (confirm) {
                          const { status: a } = await operator.setCBSwitch(
                            selectedcb.id,
                            0
                          );
                          console.log("a", a);
                          if (a) {
                            enqueueSnackbar("성공", { variant: "success" });
                            setRefresh(true);
                            setRefresh(false);
                            let changed = selectedcb;
                            changed.switch = 0;
                            setSelectedcb(changed);
                          } else {
                            enqueueSnackbar(a.error, { variant: "error" });
                            setRefresh(true);
                            setRefresh(false);
                          }
                        }
                      }}
                      color="error"
                    >
                      off
                    </Button>
                  </Box>
                </Paper>
                <Container sx={{ mt: 4, mb: 4 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 140,
                        }}
                      >
                        <Graph />
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button onClick={close}>닫기</Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
