import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { ResponsiveLine } from "@nivo/line";
import { useEffectOnce } from "react-use";

import {
  Box,
  Chip,
  Typography,
  Container,
  Grid,
  Modal,
  Paper,
  Button,
  ButtonBase,
  Card,
  Link,
} from "@mui/material";
// Generate Sales Data

export default function Chart(props) {
  const theme = useTheme();
  const [data1, setData1] = React.useState(null);

  useEffectOnce(async () => {
    const dato = props.data;
    const watt = [];
    const amp = [];
    const volt = [];
    for (let timeKey of Object.keys(dato && dato)) {
      console.log("타임키 : ", timeKey);
      const onlyTime = timeKey.substr(-2, 2);
      watt.push({ x: onlyTime, y: dato[timeKey].watt / dato[timeKey].count });
      amp.push({ x: onlyTime, y: dato[timeKey].amp / dato[timeKey].count });
      volt.push({ x: onlyTime, y: dato[timeKey].volt / dato[timeKey].count });
    }
    let datas = [
      {
        id: "Amp",
        color: "hsl(231, 70%, 50%)",
        data: amp,
      },
      // {
      //   id: "Volt",
      //   color: "hsl(56, 70%, 50%)",
      //   data: volt,
      // },
      {
        id: "Watt",
        color: "hsl(222, 70%, 50%)",
        data: watt,
      },
    ];
    console.log("데이터", datas);
    setData1(datas);
  });
  return (
    <React.Fragment>
      <ResponsiveLine
        data={data1 && data1}
        margin={{ top: 20, right: 70, bottom: 20, left: 30 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "transportation",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={3}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        enablePointLabel={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
}
