import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";
import { useHistory } from "react-router";

import Page from "components/Page";
import Header from "components/Header";
import FirstLayer from "pages/User/Home/FirstLayer";
import SecondLayer from "pages/User/Home/SecondLayer";
import ThirdLayer from "pages/User/Home/ThirdLayer";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import Card from "@mui/material/Card";
import { Container } from "@mui/material";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, CardActionArea, CardActions } from "@mui/material";

const Home = () => {
  const { setSelectedAddress, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffectOnce(async () => {
    // const { data } = await axios.post(process.env.REACT_APP_HOST + "/dashboard", null, httpHeader());
    // setData(data.result);
    setData("data.result");
  });

  React.useEffect(() => {
    // if (refresh) {
    //   (async () => {
    //     const { data } = await axios.post(process.env.REACT_APP_HOST + "/dashboard", null, httpHeader());
    //     setData(data.result);
    //   })();
    // }
  }, [refresh]);

  const params = { refresh, setRefresh, ...data };
  return (
    <Page title={"Home"}>
      {data ? (
        <React.Fragment>
          <Header />
          <Box
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "14px 14px" }}
          >
            <Container>
              {[
                {
                  title: "CC 관리",
                  buttonTitle: "바로가기",
                  click: () => {
                    history.push("/admin/powertable");
                  },
                },
                {
                  title: "데이터 관리",
                  buttonTitle: "바로가기",
                  click: () => {
                    history.push("/admin/data");
                    // alert("데이터가 부족합니다");
                    return;
                  },
                },
              ].map((v) => {
                return (
                  <Card sx={{ maxWidth: 345, mt: 2 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {v.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        ></Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => v.click()}
                      >
                        {v.buttonTitle}
                      </Button>
                    </CardActions>
                  </Card>
                );
              })}
            </Container>
          </Box>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
