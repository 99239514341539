import { createTheme } from "@mui/material/styles";

import typography from "styles/theme/typography";
import shadows from "styles/theme/shadows";
import { colors } from "@mui/material";

const mainColor = {
  firstGreen: "#07122A",
  secondLightGreen: "#32AE89",
  thirdDarkBrown: "#594f4f",
  fourthGrey: "#547980",
  fifthYello: "#e5efc4",
  white: "#fff",
  backGround: "#FFFFFF",
};
const theme = createTheme({
  palette: {
    // mode: "dark",
    background: {
      default: "#222",
    },
    primary: {
      main: colors.indigo[600],
    },
    secondary: {
      main: colors.indigo[300],
    },
    darkGrey: "#999",
    lightGrey: "#e6e6e6",
    blue: "#005CEE",
    ...mainColor,
    // text: {
    //   primary: "#45ada8"
    // }
  },
  typography,
  shadows,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .Mui-select": {
            color: mainColor.white,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: mainColor.white,
          },
          "& .MuiTabs-scrollButtons": {
            color: mainColor.white,
          },
        },
      },
    },

    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       color: mainColor.white,
    //       "& .Mui-selected": {
    //         color: mainColor.white,
    //       },
    //     },
    //   },
    // },

    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          userSelect: "auto",
          WebkitTapHighlightColor: "rgba(255, 255, 255, 0)",
          WebkitTouchCallout: "auto",
        },
        input: {
          userSelect: "text",
        },
        html: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          height: "100%",
          width: "100%",
        },
      },
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: 280,
      },
    },

    // MuiButton: {

    //   variants: [
    //     {
    //       props: { variant: 'selected' },
    //       style: {
    //         color: theme.palette.lightGrey,
    //         minWidth: "0",
    //         width: "2.8em",
    //         height: "2.2em",
    //         backgroundColor: theme.palette.white,
    //         "&:hover": {
    //           backgroundColor: theme.palette.white,
    //           opacity: 0.75,
    //         },
    //         // border: `2px dashed ${theme.palette.secondLightGreen}`,
    //       },
    //     },
    //     {
    //       props: { variant: 'unselected' },
    //       style: {
    //         color: theme.palette.lightGrey,
    //         minWidth: "0",
    //         width: "2.8em",
    //         height: "2.2em",
    //         backgroundColor: theme.palette.white,
    //         "&:hover": {
    //           backgroundColor: theme.palette.white,
    //           opacity: 0.75,
    //         },
    //         // border: `2px dashed ${theme.palette.secondLightGreen}`,
    //       },
    //     },
    //   ]
    // }
  },
});

export default theme;
