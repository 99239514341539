import { useState } from "react";

const useDialog = () => {
  const [message, setMessage] = useState(null);

  const props = {
    isOpen: Boolean(message),
    setMessage,
    message,
  };
  return [setMessage, props];
};

export default useDialog;
