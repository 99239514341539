import React from "react";

import { Typography, Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function CustomCard(props) {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Typography align="center">{props.title}</Typography>
        <Typography align="center" mt={3} className={classes.typo}>
          {props.content}
          <br /> {"MATIC"}
        </Typography>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.metalGreen,
  },
  typo: {
    color: theme.palette.firstGreen,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
}));
