import React from "react";
import { makeStyles } from "@mui/styles";
import numeral from "numeral";
import axios from "axios";
import { httpHeader, nodeFactory, treeFactory, nodeFactoryCommunity } from "utils";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { Box, Typography, Container, Grid, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import theme from "styles/theme";
import LayerTitle from "components/LayerTitle";

import LogTable from "pages/User/Home/LogTable";
import TextField from "components/TextField";
import Button from "components/Button";
import Card from "components/UserCard";
import Hierarchy from "pages/User/Home/Hierarchy";
import useInput from "hooks/useInput";
import { errorFactory } from "utils";
import GlobalContext from "context/GlobalContext";

const SecondLayer = (params) => {
  const { user, setDialogMessage } = React.useContext(GlobalContext);
  const [init, setInit] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [recommendTree, setRecommendTree] = React.useState(null);
  const [communityTree, setCommunityTree] = React.useState(null);

  const classes = useStyles();
  const withdrawInput = useInput(0);
  const passwordInput = useInput("");
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpen = (type) => {
    if (type === "community" && Number(params.buySum) === 0) {
      setDialogMessage(t("구매한 패키지가 없습니다."));
    } else {
      setOpen(type);
    }
  };

  const withdrawOnClick = async () => {
    if (!passwordInput.value) {
      return enqueueSnackbar(t("잘못된 비밀번호입니다."), { variant: "error" });
    }

    if (Number(withdrawInput.value) > params.balance) {
      return enqueueSnackbar(t("출금 잔액이 부족합니다."), { variant: "error" });
    }
    const body = {
      amount: withdrawInput.value,
      password: passwordInput.value,
    };
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/withdraw", body, httpHeader());

    if (data.status) {
      params.setRefresh(!params.refresh);
      return enqueueSnackbar(t("출금 신청이 완료되었습니다."), { variant: "success" });
    } else {
      return enqueueSnackbar(errorFactory(data.error), { variant: "error" });
    }
  };

  const getLogs = async ({ log, page }) => {
    const body = { log, page };
    setLoading(log);
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/dashboard/logs", body, httpHeader());
    setLoading(false);
    return data;
  };

  const initLogs = async () => {
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/dashboard/logs/init", { page: 0 }, httpHeader());
    setInit({
      package: data.result.packageList,
      packageCount: data.result.packageListCount,
      withdraw: data.result.withdrawList,
      withdrawCount: data.result.withdrawListCount,
      communityIncome: data.result.communityIncomeList,
      communityIncomeCount: data.result.communityIncomeListCount,
      recommendIncome: data.result.recommendIncomeList,
      recommendIncomeCount: data.result.recommendIncomeListCount,
      withdrawIncome: data.result.withdrawIncomeList,
      withdrawIncomeCount: data.result.withdrawIncomeListCount,
    });
  };

  const getRecommendTree = async () => {
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/dashboard/tree/recommend", null, httpHeader());
    if (data.result.length === 0) {
      setRecommendTree([
        {
          children: [],
          detail: null,
          id: user.email,
          name: user.email,
          parent_id: null,
        },
      ]);
    } else {
      const node = nodeFactory(data.result, data.result[0]);
      const sorted = treeFactory(node);
      setRecommendTree(sorted);
    }
  };

  const getCommunityTree = async () => {
    const { data } = await axios.post(process.env.REACT_APP_HOST + "/dashboard/tree/community", null, httpHeader());
    if (data.result.length === 0) {
      return;
    }
    const node = nodeFactoryCommunity(data.result);
    const sorted = treeFactory(node);
    setCommunityTree(sorted);
  };

  React.useEffect(() => {
    initLogs();
  }, [params.refresh]);

  return (
    <Container maxWidth="lg">
      <Modal
        open={Boolean(open)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          {open === "community" && <Hierarchy tree={communityTree} getTree={getCommunityTree} />}
          {open === "recommend" && <Hierarchy tree={recommendTree} getTree={getRecommendTree} />}
        </Box>
      </Modal>
      <LayerTitle id="b" title={t("폴리곤 수당")} />{" "}
      <Grid container spacing={1}>
        {[
          { text: t("스폰서 수당 업라인"), value: params.communityUplineSum },
          { text: t("스폰서 수당 다운라인"), value: params.communityDownlineSum },
          { text: t("추천수당"), value: params.recommendSum },
          { text: t("출금 신청 가능 금액"), value: params.balance },
        ].map((item, index) => {
          return (
            <Grid key={item.text + index} item xs={6} md={12}>
              <Card title={item.text} content={numeral(item.value).format("0,0.[0000]")} />
            </Grid>
          );
        })}
      </Grid>
      <LayerTitle id="c" title="폴리곤 지갑" />{" "}
      <Box component="form" noValidate autoComplete="off">
        {[
          {
            color: Number(withdrawInput.value) > params.balance ? "red" : theme.palette.firstGreen,
            value: withdrawInput.value,
            onChange: withdrawInput.onChange,
            type: "text",
            text: t("출금 수량 (최소 10 MATIC)"),
          },
          {
            color: theme.palette.firstGreen,
            value: numeral(params.balance).format("0,0.[0000]"),
            type: "text",
            text: t("출금 가능금액"),
          },
          {
            color: Number(withdrawInput.value) > params.balance ? "red" : theme.palette.firstGreen,
            value: numeral(
              withdrawInput.value === 0 ? 0 : ((withdrawInput.value * 0.97) / 100) * params.myGrade.withdraw_body
            ).format("0,0.[0000]"),
            type: "text",
            text: t("재투자 (MATIC)"),
          },
          {
            color: Number(withdrawInput.value) > params.balance ? "red" : theme.palette.firstGreen,
            value: numeral(
              withdrawInput.value === 0 ? 0 : ((withdrawInput.value * 0.97) / 100) * params.myGrade.withdraw_ratio
            ).format("0,0.[0000]"),
            type: "text",
            text: t("인출가능 수량 (MATIC)"),
          },
          {
            color: theme.palette.firstGreen,
            value: passwordInput.value,
            onChange: passwordInput.onChange,
            type: "password",
            text: t("출금 비밀번호"),
          },
        ].map((item, index) => (
          <Box
            key={item.text + index}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            align
            mt={index !== 0 ?? 2}
          >
            <Typography mb={1} color={theme.palette.white}>
              {item.text}
            </Typography>
            <TextField
              inputProps={{ style: { color: item.color, textAlign: "right" } }}
              onChange={item.onChange ?? null}
              type={item.type}
              value={item.value}
            />
          </Box>
        ))}
        <Box py={3} display="flex" justifyContent="center" alignItems="center">
          <Button onClick={withdrawOnClick} className={classes.selectedButton} size="large" sx={{ width: 240 }}>
            {t("출금 확인")}
          </Button>
        </Box>
      </Box>
      <LayerTitle id="d" title={"폴리곤 히스토리"} />{" "}
      <Grid container spacing={1}>
        {[
          { text: t("총 투자금액"), value: numeral(params.buySum).format("0,0.[0000]") },
          { text: t("총 출금액"), value: numeral(params.withdrawSumPoint).format("0,0.[0000]") },
          {
            text: t("재 투자금액"),
            value: numeral(Number(params.bodyPrice) - Number(params.buySum)).format("0,0.[0000]"),
          },
          {
            text: t("합산 밸런스"),
            value: numeral(params.bodyPrice).format("0,0.[0000]"),
          },
        ].map((item, index) => (
          <Grid key={item.text + index} item xs={6} md={12}>
            <Card title={item.text} content={item.value} />
          </Grid>
        ))}
        <Grid item xs={6} md={12}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <ContactMailIcon
              sx={{
                color: theme.palette.secondLightGreen,
                fontSize: "6.0rem",
              }}
            />
            <Button
              onClick={handleOpen.bind(null, "recommend")}
              className={classes.selectedButton}
              size="large"
              sx={{ width: "6.0rem" }}
            >
              {t("추천 라인")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} md={12}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CardTravelIcon
              sx={{
                color: theme.palette.secondLightGreen,
                fontSize: "6.0rem",
              }}
            />
            <Button
              onClick={handleOpen.bind(null, "community")}
              className={classes.selectedButton}
              size="large"
              sx={{ width: "6.0rem" }}
            >
              {t("스폰서 라인")}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {init &&
        [
          {
            text: t("패키지 구매내역"),
            log: "package",
            labels: ["패키지", "날짜"],
          },
          {
            text: t("폴리곤 출금 내역"),
            log: "withdraw",
            labels: ["수량", "상태", "날짜"],
          },
          {
            text: t("추천 수당 내역"),
            log: "recommendIncome",
            labels: ["수량", "날짜"],
          },
          {
            text: t("스폰서 수당 내역"),
            log: "communityIncome",
            labels: ["수량", "날짜"],
          },
          {
            text: t("출금 수당 내역"),
            log: "withdrawIncome",
            labels: ["수량", "날짜"],
          },
        ].map((item, index) => {
          return (
            <React.Fragment key={item.text + index}>
              <Typography mt={index === 0 ?? 4} mb={1} color={theme.palette.white}>
                {item.text}
              </Typography>
              <LogTable loading={loading} init={init} log={item.log} getLogs={getLogs} labels={item.labels} />
            </React.Fragment>
          );
        })}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.lightGrey,
    color: theme.palette.darkGrey,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
  },
  selectedButton: {
    background:
      "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    color: theme.palette.lightGrey,
    backgroundColor: theme.palette.firstGreen,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
  },
}));

export default SecondLayer;
