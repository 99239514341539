import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import Header from "pages/User/Home/Header";
import FirstLayer from "pages/User/Home/FirstLayer";
import SecondLayer from "pages/User/Home/SecondLayer";
import ThirdLayer from "pages/User/Home/ThirdLayer";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import { Box, Chip, Button, Container, Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";
import { useSnackbar } from "notistack";

import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

const CreationModal = (props) => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });

  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [panel, setPanel] = React.useState(null);
  const [cb, setCB] = React.useState(null);

  const [userAccess, setUserAccess] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);

    const cc = await operator.getCCs();
    setcc(cc.result);

    const paneldata = await operator.getPanels();
    const cbs = paneldata.map((v) => {
      return operator.getCBs(v.id);
    });
    const cbData = await Promise.all(cbs);
    setCB(cbData);
    setPanel(paneldata);
    setUsers(await operator.getUsers());

    const selectedData = cbData.map((v) => {
      return v.map((j) => {
        let copyj = j;
        copyj.selected = false;
        return copyj;
      });
    });
    setSelected(selectedData);

    setData(paneldata);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        console.log("여기도 안들어온다고?");
        const operator = new Operator(user.id);
        setoperator(operator);

        const cc = await operator.getCCs();

        setcc(cc.result);

        const paneldata = await operator.getPanels();
        setPanel(paneldata);
        const userData = await operator.getUsers();
        setUsers(userData);

        const cbs = paneldata.map((v) => {
          return operator.getCBs(v.id);
        });
        const cbData = await Promise.all(cbs);
        setCB(cbData);
        console.log("panelCBData", cbData);
        const selectedData = cbData.map((v) => {
          return v.map((j) => {
            let copyj = j;
            copyj.selected = false;
            return copyj;
          });
        });
        setSelected(selectedData);

        setData(paneldata);
        setRefresh(false);
      })();
    }
  }, [refresh]);
  React.useEffect(() => {
    console.log(selected);
  }, [selected]);

  const createUser = async () => {
    const userId = idInput.value;
    const password = passwordInput.value;
    if (password.length < 4) {
      return setDialogMessage(t("비밀번호가 짧습니다(4자이상)"));
    }
    if (userId.length < 4) {
      return setDialogMessage(t("유저아이디가 짧습니다(4자이상)"));
    }
    console.log(selected);

    const body = {
      userId: userId.trim(),
      password: password.trim(),
    };
    // 유저 아이디 체크
    const { data: checkId } = await axios.post(
      `${process.env.REACT_APP_HOST}/user/isExist`,
      body
    );
    if (checkId.result) {
      return setDialogMessage(t("유저아이디가 존재합니다"));
    }

    const userBody = {
      user: {
        account: body.userId,
        password: body.password,
        is_admin: false,
        is_manager: false,
        is_user: true,
        token: "user",
        cc_id: cc.id,
      },
      cc_id: cc.id,
    };
    // 계정 생성
    const { data: registerData } = await axios.post(
      `${process.env.REACT_APP_HOST}/operator/user/create`,
      userBody
    );
    if (!registerData.status) {
      return setDialogMessage(t("시스템 에러발생(e:144"));
    }
    const [userInfo] = registerData.result;
    console.log("유저인포", userInfo);
    let thebody = [];
    selected.map((v, i) => {
      selected[i].map((j, t) => {
        if (j.selected) {
          let copyj = j;
          copyj.cc_id = cc.id;
          thebody.push(copyj);
        }
      });
    });
    console.log("thebody", thebody);
    const accessBody = { access: thebody, user_id: userInfo.id };
    console.log("엑세스 바디", accessBody);
    //배전에 대한 유저 엑세스 만들기
    const { data: accessData } = await axios.post(
      `${process.env.REACT_APP_HOST}/operator/useraccess/create`,
      accessBody
    );
    console.log("엑세스데이터", accessData);

    if (accessData.status) {
      props.setRefresh(true);
      setRefresh(true);
      setDialogMessage(t("회원추가가 완료되었습니다"));
      props.close();
    } else {
      return setDialogMessage("다시 시도해주세요");
    }
  };
  const params = { refresh, setRefresh, ...data };

  return (
    <React.Fragment>
      {data ? (
        <Container>
          <Box sx={{ width: "90vw" }}>
            <Paper sx={{ p: 2 }}>
              <Box>
                <Chip label={"아시아 CC"} />
              </Box>
              <Container>
                <form>
                  <Box>
                    {[
                      {
                        placeholder: t("아이디"),
                        type: "id",
                        value: idInput.value,
                        onChange: idInput.onChange,
                      },
                      {
                        placeholder: t("비밀번호"),
                        type: "password",
                        value: passwordInput.value,
                        onChange: passwordInput.onChange,
                      },
                    ].map((option) => {
                      return (
                        <TextField
                          inputProps={{
                            style: { color: theme.palette.firstGreen },
                          }}
                          sx={{
                            mt: 2,
                            "& label.Mui-focused": {
                              color: theme.palette.firstGreen,
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: theme.palette.firstGreen,
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                              "&:hover fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                            },
                          }}
                          fullWidth
                          required
                          autocomplete="off"
                          key={option.placeholder}
                          size="small"
                          {...option}
                        />
                      );
                    })}
                  </Box>
                </form>
              </Container>
              <Box>
                <Container>
                  <Typography my={2}>할당할 차단기</Typography>

                  {/* 맵 두번 돌려서  1. 배전 이름 쓰기, 2. 배전 인덱스에 관한 cb애들 뽑기 */}
                  <Box mt={3}>
                    {panel &&
                      panel.map((v, i) => {
                        const Title = (
                          <Typography mb={1}>{v.title} 배전</Typography>
                        );
                        const chips = selected[i].map((j, t) => {
                          return (
                            <Chip
                              sx={{ mb: 2, zIndex: 99 }}
                              color="primary"
                              size={"small"}
                              variant={j.selected ? "filled" : "outlined"}
                              label={j.alias + "번"}
                              onClick={() => {
                                let newArr = [...selected];
                                newArr[i][t].selected = !newArr[i][t].selected;
                                setSelected(newArr);
                              }}
                            ></Chip>
                          );
                        });

                        return (
                          <React.Fragment>
                            {Title}
                            {chips.length && chips.map((v) => v)}
                          </React.Fragment>
                        );
                      })}
                  </Box>
                </Container>
                <Button onClick={createUser} variant={"outlined"} fullWidth>
                  {t("생성")}
                </Button>
                <Button onClick={props.close} variant={"outlined"} fullWidth>
                  {t("취소")}
                </Button>
              </Box>
              <Box></Box>
            </Paper>
          </Box>
        </Container>
      ) : (
        <Page>
          <Loader />
        </Page>
      )}
    </React.Fragment>
  );
};

export default CreationModal;
