import React from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import { Box, Typography, Container, Stack, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "styles/theme";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import LayerTitle from "components/LayerTitle";

import Button from "components/Button";

const ThirdLayer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const sx = {
    "&:hover": {
      opacity: 0.75,
    },
    cursor: "pointer",
    color: theme.palette.secondLightGreen,
    fontSize: "3.0rem",
  };

  const pdfOnClick = () => {
    enqueueSnackbar(t("준비중입니다."), { variant: "error" });
  };
  const videoOnClick = () => {
    enqueueSnackbar(t("준비중입니다."), { variant: "error" });
  };

  return (
    <Container maxWidth="lg">
      <LayerTitle id="e" title={t("폴리곤 마케팅")} />
      <Stack>
        <Box
          onClick={pdfOnClick}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          my={1}
          sx={sx}
        >
          <PictureAsPdfIcon sx={sx} />
          <Typography>한국어 PDF</Typography>
        </Box>
        <Box
          onClick={pdfOnClick}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          my={1}
          sx={sx}
        >
          <PictureAsPdfIcon sx={sx} />
          <Typography>English PDF</Typography>
        </Box>
        <Box
          onClick={pdfOnClick}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          my={1}
          sx={sx}
        >
          <PictureAsPdfIcon sx={sx} />
          <Typography>Chinese PDF</Typography>
        </Box>
      </Stack>
      <Divider sx={{ background: theme.palette.white }} />
      <Stack>
        <Box
          onClick={videoOnClick}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          my={1}
          sx={sx}
        >
          <VideoLibraryIcon sx={sx} />
          <Typography>한국어 비디오</Typography>
        </Box>
        <Box
          onClick={videoOnClick}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          my={1}
          sx={sx}
        >
          <VideoLibraryIcon sx={sx} />
          <Typography>English Video</Typography>
        </Box>
        <Box
          onClick={videoOnClick}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          my={1}
          sx={sx}
        >
          <VideoLibraryIcon sx={sx} />
          <Typography>Chinese Video</Typography>
        </Box>
      </Stack>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ textAlign: "center" }}
      >
        <LayerTitle id="f" title={t("폴리곤 플랜")} />
        <Button className={classes.selectedButton} size="large" sx={{ width: 240 }}>
          {t("폴리곤 플랜 문의")}
        </Button>
        <Typography color={theme.palette.white} mt={2}>
          © 2021 Polygon Plan All Rights Reserved.{" "}
        </Typography>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.lightGrey,
    color: theme.palette.darkGrey,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
  },
  selectedButton: {
    background:
      "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    color: theme.palette.lightGrey,
    backgroundColor: theme.palette.firstGreen,
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
    },
  },
}));

export default ThirdLayer;
