import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Page from "components/Page";
import useInput from "hooks/useInput";
import GuestForm from "../Guest/GuestForm";
import Intro from "components/Intro";
import theme from "styles/theme";

const Login = ({ history }) => {
    const classes = useStyles();
    const emailInput = useInput("");
    const passwordInput = useInput("");
    const { t } = useTranslation();

    const params = {
        email: emailInput.value,
        password: passwordInput.value,
        page: "login",
    };

    return (
        <Page title={t("로그인")}>
            <Container maxWidth="xs" className={classes.root}>
                <Intro />
                <GuestForm params={params}>
                    <form>
                        {[
                            { placeholder: t("아이디"), type: "email", value: emailInput.value, onChange: emailInput.onChange },
                            {
                                placeholder: t("비밀번호"),
                                type: "password",
                                value: passwordInput.value,
                                onChange: passwordInput.onChange,
                            },
                        ].map((option) => {
                            return (
                                <TextField
                                    inputProps={{ style: { color: theme.palette.firstGreen } }}
                                    sx={{
                                        mt: 2,
                                        "& label.Mui-focused": {
                                            color: theme.palette.firstGreen,
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: theme.palette.firstGreen,
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: theme.palette.firstGreen,
                                            },
                                            "&:hover fieldset": {
                                                borderColor: theme.palette.firstGreen,
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: theme.palette.firstGreen,
                                            },
                                        },
                                    }}
                                    fullWidth
                                    required
                                    key={option.placeholder}
                                    size="small"
                                    {...option}
                                />
                            );
                        })}
                        <Box mt={3}>
                            <Button type="submit" className={classes.selectedButton} fullWidth>
                                {t("로그인")}
                            </Button>
                            {/* <Button
                className={classes.selectedButton}
                onClick={() => history.push("/register")}
                type="button"
                fullWidth
              >
                {t("회원가입")}
              </Button>
              <Button
                className={classes.selectedButton}
                onClick={() => history.push("/forgot")}
                type="button"
                fullWidth
              >
                {t("비밀번호 찾기")}
              </Button> */}
                        </Box>
                    </form>
                </GuestForm>
            </Container>
        </Page>
    );
};

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 20,
        height: 20,
    },
    root: {
        paddingTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    button: {
        backgroundColor: theme.palette.lightGrey,
        color: theme.palette.darkGrey,
        "&:hover": {
            background:
                "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
        },
    },
    selectedButton: {
        background:
            "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
        color: theme.palette.lightGrey,
        backgroundColor: theme.palette.firstGreen,
        "&:hover": {
            background:
                "linear-gradient(90deg, rgba(84,121,128,0.8533788515406162) 0%, rgba(84,108,128,1) 35%, rgba(50,174,137,1) 100%)",
        },
        marginBottom: "8px",
    },
}));

export default Login;
