import React from "react";

import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function LayerTitle(props) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={2}
      pt={15}
      id={props.id}
    >
      <Typography
        variant="h3"
        component="h2"
        style={{
          background:
            "linear-gradient(90deg, rgba(229,239,196,1) 0%, rgba(69,173,168,1) 35%, rgba(89,79,79,1) 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.white,
    background:
      "linear-gradient(90deg, rgba(229,239,196,1) 0%, rgba(69,173,168,1) 35%, rgba(89,79,79,1) 100%)",
  },
  typo: {
    // color: theme.palette.firstGreen,
  },
  divider: {
    background: theme.palette.firstGreen,
  },
}));
