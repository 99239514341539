import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";

import Loader from "components/Loader";
import { httpHeader } from "utils";

import { Box, Chip, Button, Container, Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import { Operator } from "classes";
import DataTable from "pages/Admin/DataTableAdmin";

import { useModal } from "react-hooks-use-modal";
import useInput from "hooks/useInput";

import UserTab from "../User/Home/UserTab";

//props 연결

const Home = (props) => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const idInput = useInput("");
  const passwordInput = useInput("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const operatorAPI = async () => {
    const operator = new Operator(user.id);
    setoperator(operator);
    if (user.is_admin) {
      const cc = await operator.getCCs();
      console.log("어드민 cc", cc);
      setcc(cc.result);
    } else {
      const usersCCidData = await operator.getUsersCCid(user.account);
      console.log("유저씨씨아이디", usersCCidData.result);
      const cc = await operator.getCC(usersCCidData.result.cc_id);
      console.log("낫어드민 cc", cc);

      setcc(cc.result);
    }
    const panel = await operator.getPanels();
    setData(panel);
    setUsers(await operator.getUsers());
  };

  const userAPI = async () => {
    const operator = new Operator(user.id);
    setoperator(operator);
    const cc = await operator.getCCs();

    setcc(cc.result);
    const panel = await operator.getUserPanels();
    setData(panel);
    setUsers(await operator.getUsers());
  };
  useEffectOnce(async () => {
    if (user.is_manager) {
      await operatorAPI();
    } else {
      await userAPI();
    }
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        if (user.is_manager) {
          await operatorAPI();
        } else {
          await userAPI();
        }
        setRefresh(false);
      })();
    }
  }, [refresh]);

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value} centered>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  centered
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="분전" value="1" />
                  {user.is_manager && <Tab label="유저 계정" value="2" />}
                  {user.is_manager && <Tab label="통계" value="3" />}
                </TabList>
              </Box>
              <TabPanel value="3">
                <DataTable
                  cc={cc}
                  panel={data}
                  setPanel={data}
                  // operators={props.operators}
                  refresh={refresh}
                  setRefresh={setRefresh}
                ></DataTable>
              </TabPanel>
              <TabPanel value="1">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Chip sx={{ mb: 2 }} label={cc.title} variant="outlined" />
                  </Box>

                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>분전</TableCell>
                          <TableCell>타입</TableCell>
                          <TableCell>전원</TableCell>
                          <TableCell>삭제</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data &&
                          data.map((row) => (
                            <TableRow
                              key={row.title}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              onClick={() => {
                                history.push(
                                  `/user/powerdetail/${row.cc_id}/${row.id}`
                                );
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.title}
                              </TableCell>
                              <TableCell>{row.direction}</TableCell>
                              <TableCell>
                                <Chip
                                  size={"small"}
                                  // color={row.switch ? "primary" : "error"}
                                  label={row.switch ? "ON" : "OFF"}
                                  sx={{
                                    backgroundColor: row.switch
                                      ? "#D32E2E"
                                      : "#008000",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Chip
                                  size={"small"}
                                  color={"error"}
                                  variant={"outlined"}
                                  label={"삭제"}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </TabPanel>
              <UserTab></UserTab>
            </TabContext>
          </Box>
          <Box></Box>
          <Modal>
            <Container>
              <Box sx={{ width: "90vw" }}>
                <Paper
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ p: 2 }}
                >
                  <Box>
                    <Chip label={"아시아 CC"} />
                  </Box>
                  <Box>
                    {[
                      {
                        placeholder: t("아이디"),
                        type: "id",
                        value: idInput.value,
                        onChange: idInput.onChange,
                      },
                      {
                        placeholder: t("비밀번호"),
                        type: "password",
                        value: passwordInput.value,
                        onChange: passwordInput.onChange,
                      },
                    ].map((option) => {
                      return (
                        <TextField
                          inputProps={{
                            style: { color: theme.palette.firstGreen },
                          }}
                          sx={{
                            mt: 2,
                            "& label.Mui-focused": {
                              color: theme.palette.firstGreen,
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: theme.palette.firstGreen,
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                              "&:hover fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: theme.palette.firstGreen,
                              },
                            },
                          }}
                          fullWidth
                          required
                          key={option.placeholder}
                          size="small"
                          {...option}
                        />
                      );
                    })}
                    <Box mt={3}>
                      <Box mt={3}>
                        <Typography my={2}>할당할 차단기</Typography>
                        {[...new Array(10)].map(() => (
                          <Chip label="Clickable" clickable color="primary" />
                        ))}
                      </Box>
                      <Button onClick={close} fullWidth>
                        {t("계정생성")}
                      </Button>
                    </Box>
                  </Box>
                  <Box></Box>
                </Paper>
              </Box>
            </Container>
          </Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
