import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";
import Header from "pages/User/Home/Header";
import FirstLayer from "pages/User/Home/FirstLayer";
import SecondLayer from "pages/User/Home/SecondLayer";
import ThirdLayer from "pages/User/Home/ThirdLayer";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Container,
  Grid,
  Modal,
  Paper,
  Button,
  ButtonBase,
} from "@mui/material";

import Loader from "components/Loader";
import { httpHeader } from "utils";

const Home = () => {
  const { setSelectedAddress, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();

  useEffectOnce(async () => {
    setData("data.result");
  });

  React.useEffect(() => {
    // if (refresh) {
    //   (async () => {
    //     const { data } = await axios.post(process.env.REACT_APP_HOST + "/dashboard", null, httpHeader());
    //     setData(data.result);
    //   })();
    // }
  }, [refresh]);

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {data ? (
        <React.Fragment>
          <Box></Box>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
