import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Drawer, Toolbar, List, ListItemIcon, ListItemText, ListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@mui/styles";
import { useEffectOnce } from "react-use";
const NavigationDrawer = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const { drawerIsOpen, setDrawerIsOpen, setIsLogin, isLogin, setUser, adminFocused, setAdminFocused } =
    React.useContext(GlobalContext);
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const classes = useStyles();
  const logout = () => {
    sessionStorage.removeItem("token");
    setIsLogin(false);
    setDrawerIsOpen(false);
    setUser(null);
  };

  const ListItemOnClick = ({ table }) => {
    setAdminFocused(table);
    history.push(table);
  };

  useEffectOnce(() => {
    if (!adminFocused) {
      setAdminFocused(location.pathname);
    }
  });

  return (
    <Drawer
      anchor={smView ? "top" : "left"}
      sx={{
        display: { xs: "block" },
        flexShrink: 0,
        width: smView ? "100%" : 240,
        [`& .MuiDrawer-paper`]: { width: smView ? "100%" : 240, boxSizing: "border-box" },
      }}
      variant="persistent"
      open={smView ? drawerIsOpen : true}
      onClose={() => setDrawerIsOpen(!drawerIsOpen)}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }} className={classes.root}>
        <List>
          {[
            {
              path: "/admin",
              icon: <HomeIcon />,
              text: t("어드민 홈"),
            },
            {
              path: "/admin/notice",
              icon: <MenuIcon />,
              text: t("공지사항 글쓰기"),
            },
          ].map((item, index) => {
            const color = adminFocused === item.path && theme.palette.primary.main;

            return (
              <ListItem key={item.text + index} onClick={ListItemOnClick.bind(null, { table: item.path })} button>
                <ListItemIcon>
                  {React.cloneElement(item.icon, {
                    color: color ? "primary" : "",
                  })}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color }} primary={item.text} />
              </ListItem>
            );
          })}
          {props.adminTables &&
            props.adminTables.map(({ table, title }) => {
              const path = "/admin/" + table;
              const color = adminFocused === path && theme.palette.primary.main;
              return (
                <ListItem button onClick={ListItemOnClick.bind(null, { table: path })} key={title}>
                  <ListItemIcon>
                    <InboxIcon color={color ? "primary" : ""} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ color }} primary={title} />
                </ListItem>
              );
            })}

          {isLogin && (
            <ListItem onClick={logout} button>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t("로그아웃")} />
            </ListItem>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default NavigationDrawer;
