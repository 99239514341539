import React from "react";
import { useEffectOnce } from "react-use";
import axios from "axios";

import Page from "components/Page";

import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Circuit from "resources/images/circuit.png";
import Power from "resources/images/power.png";

import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import { httpHeader } from "utils";
import Graph from "pages/User/Graph.js";
import { Operator } from "classes";
import { useSnackbar } from "notistack";
import Radio from "@mui/material/Radio";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  Box,
  Chip,
  Typography,
  Container,
  Grid,
  Modal,
  Paper,
  Button,
  ButtonBase,
  Card,
  Link,
  Fab,
  Checkbox,
  CardActions,
  CardContent,
} from "@mui/material";
// import Popover from "@mui/material/Popover";

import NavigationIcon from "@mui/icons-material/Navigation";

import { useModal } from "react-hooks-use-modal";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Home = ({ match }) => {
  const { user, setNetwork, setDialogMessage } =
    React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const { t } = useTranslation();
  const [selectedcb, setSelectedcb] = React.useState(null);
  const history = useHistory();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [InfoModal, infoOpen, infoClose, isInfoOpen] = useModal("root", {
    preventScroll: false,
    closeOnOverlayClick: true,
  });
  const [cc, setcc] = React.useState(null);
  const [operator, setoperator] = React.useState(null);
  const [selectedPanel, setSelectedPanel] = React.useState(null);
  const { cc_id } = match.params;
  const { panel_id } = match.params;
  const [selectedCBs, setSelectedCBs] = React.useState([]);

  // schedules
  const [scheduleGroups, setScheduleGroups] = React.useState(null);
  const [schedules, setSchedules] = React.useState(null);
  const [cbSchedules, setCBSchedules] = React.useState(null);
  const [selectedGroupId, setSelectedGroupId] = React.useState(null);
  const [selectedSchedules, setSelectedSchedules] = React.useState(null);

  // const handleChange = (event) => {
  // if (selectedCBs.includes(event.target.value)) {
  //   console.log("리무브드", event.target.value);
  //   const removedFrom = selectedCBs.filter(
  //     (v) => v !== String(event.target.value)
  //   );
  //   setSelectedCBs([...removedFrom]);
  //   return;
  // }
  // let copyarray = selectedCBs;
  // copyarray.push(String(event.target.value));
  // setSelectedCBs(copyarray);
  // return;
  // };

  function getschedulesFromGroupId(groupId, schedules) {
    return schedules.result.map((v) => v.group_id === groupId);
  }

  const handleChange = (event, cbobj) => {
    if (selectedCBs.includes(String(cbobj.id))) {
      const removedFrom = selectedCBs.filter((v) => v !== String(cbobj.id));
      setSelectedCBs(removedFrom);
      return;
    }
    let copyarray = selectedCBs;
    copyarray.push(String(cbobj.id));
    setSelectedCBs(copyarray);
    setRefresh(true);
    return;
  };
  // const initSchedules = async (cbData) => {
  //   const scheduleGroupsData = await operator.getSchedulegroup();
  //   const schedulesData = await operator.getSchedules();
  //   const cbSchedulesData = await operator.getCBschedules();
  //   console.log("씨비 데이터", cbData);
  //   console.log("씨비 scheduleGroupsData", scheduleGroupsData);
  //   console.log("씨비 schedulesData", schedulesData);
  //   console.log("씨비 cbSchedulesData", cbSchedulesData);
  //   setScheduleGroups(scheduleGroupsData);
  //   setSchedules(schedulesData);
  //   setCBSchedules(cbSchedulesData);
  // };
  // Popovers
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popen = Boolean(anchorEl);
  const id = popen ? "simple-popover" : undefined;
  // Popovers

  const cbwithSchedule = (cb, scheduleGroups, schedules, cbSchedules) => {
    // cb 돌리면서 씨비랑 맞추고 스케쥴 그룹 넣고
    const cbwithgroups = cb.map((v, i) => {
      // cbSchedules 에서 cb_index와 v.index가 맞으면 그룹 아이디 넣고 살리기
      if (true) {
        return;
      }
    });
    // cb 돌리면서 스케쥴 넣고
  };

  // React.useEffect(() => {
  //   setRefresh(true);
  // }, [selectedCBs]);
  const LeftCircuit = (cbobj) => {
    return (
      <Paper sx={{}}>
        {/* container */}
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
        >
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item>
            <Checkbox
              color="default"
              checked={selectedCBs.includes(String(cbobj.id))}
              onChange={(e) => handleChange(e, cbobj)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid xs={1} sx={{ border: "solid" }} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              #{cbobj.alias}
            </Typography>
          </Grid>

          <Grid
            xs={2}
            onClick={async () => {
              setSelectedcb(cbobj);
              // 여기
              console.log(cbobj);
              if (!!!cbobj.cbSchedule) {
                return;
              }
              const scheduleData = schedules.result.filter((v) => {
                return cbobj.cbSchedule.group_id === v.group_id;
              });
              // const scheduleData = await operator.getSchedule(
              //   selectedcb && selectedcb.cbSchedule.group_id
              // );
              setSelectedSchedules(scheduleData);
              // console.log(scheduleData);
              infoOpen();
            }}
            sx={{
              border: "solid",
              backgroundColor: cbobj.cbSchedule?.id ? "red" : "green",
              overflow: "auto",
              height: "auto",
            }}
            item
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                align="center"
                noWrap={false}
                sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
              >
                {cbobj.cbSchedule?.id
                  ? `스케쥴 ON(#${cbobj.cbSchedule.id})`
                  : "스케쥴 OFF"}
              </Typography>
              <Box
                sx={{ width: "100%" }}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                flexWrap="wrap"
                alignItems="center"
              >
                {schedules.result
                  .filter((v) => {
                    return cbobj.cbSchedule?.group_id === v.group_id;
                  })
                  .map((t) => (
                    <Chip
                      size="small"
                      color={t.type ? "primary" : "error"}
                      label={t.time}
                      sx={{ margin: "1px" }}
                    ></Chip>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
        </Grid>
      </Paper>
    );
  };
  const rightCircuit = (cbobj) => {
    return (
      <Paper sx={{}}>
        {/* container */}
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
        >
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          {/* image (6) */}
          <Grid
            xs={1}
            sx={{
              backgroundImage: `url(${Circuit})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            item
          ></Grid>
          <Grid
            xs={2}
            onClick={async () => {
              setSelectedcb(cbobj);
              // 여기
              console.log(cbobj);
              if (!!!cbobj.cbSchedule) {
                return;
              }
              const scheduleData = schedules.result.filter((v) => {
                return cbobj.cbSchedule.group_id === v.group_id;
              });
              // const scheduleData = await operator.getSchedule(
              //   selectedcb && selectedcb.cbSchedule.group_id
              // );
              setSelectedSchedules(scheduleData);
              // console.log(scheduleData);
              infoOpen();
            }}
            sx={{
              border: "solid",
              backgroundColor: cbobj.cbSchedule?.id ? "red" : "green",
              overflow: "auto",
              height: "auto",
            }}
            item
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                align="center"
                noWrap={false}
                sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
              >
                {cbobj.cbSchedule?.id
                  ? `스케쥴 ON(#${cbobj.cbSchedule.id})`
                  : "스케쥴 OFF"}
              </Typography>
              <Box
                sx={{ width: "100%" }}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                flexWrap="wrap"
                alignItems="center"
              >
                {schedules.result
                  .filter((v) => {
                    return cbobj.cbSchedule?.group_id === v.group_id;
                  })
                  .map((t) => (
                    <Chip
                      size="small"
                      color={t.type ? "primary" : "error"}
                      label={t.time}
                      sx={{ margin: "1px" }}
                    ></Chip>
                  ))}
              </Box>
            </Box>
          </Grid>
          {/* 
          <Grid xs={1} sx={{ border: "solid" }} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              {cbobj.amp}A
            </Typography>
          </Grid> */}
          {/* number */}
          <Grid xs={1} sx={{ border: "solid" }} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              #{cbobj.alias}
            </Typography>
          </Grid>
          <Grid xs={1} sx={{}} item>
            <Checkbox
              color="default"
              checked={selectedCBs.includes(String(cbobj.id))}
              onChange={(e) => handleChange(e, cbobj)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
        </Grid>
      </Paper>
    );
  };

  const centerCircuit = (cbobj) => {
    return (
      <Paper sx={{ p: 1, m: 1, height: "100%" }}>
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
          sx={{
            padding: "0px",
            height: "85px",
          }}
        >
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
          <Grid
            xs={2}
            sx={{ border: "solid" }}
            display="flex"
            flexDirection="column"
            alignContent="center"
          >
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.alias}번</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="center"
              alignContent="center"
              sx={{
                border: "solid",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <Typography sx={{ margin: "auto" }}>{cbobj.amp}A</Typography>
            </Box>
          </Grid>

          <Grid
            xs={2}
            sx={{
              border: "solid",
              padding: "0px",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundImage: `url(${Circuit})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    setSelectedcb(cbobj);
                    open();
                  }}
                  variant="contained"
                  color={cbobj.switch ? "error" : "primary"}
                >
                  {cbobj.switch ? "스케쥴 ON" : "스케쥴 OFF"}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={2} sx={{}} item></Grid>
          <Grid xs={1} sx={{}} item></Grid>
        </Grid>
      </Paper>
    );
  };

  const centerPower = (cbobj, cbNext) => {
    return (
      <Paper sx={{}}>
        {/* container */}
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={0}
        >
          <Grid xs={1} sx={{ border: "solid" }} item></Grid>
          <Grid xs={1} sx={{ border: "solid" }} item>
            <Typography
              align="center"
              noWrap={false}
              sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
            >
              #{cbobj.alias}
            </Typography>
          </Grid>
          <Grid
            xs={2}
            onClick={async () => {
              setSelectedcb(cbobj);
              // 여기
              console.log(cbobj);
              if (!!!cbobj.cbSchedule) {
                return;
              }
              const scheduleData = schedules.result.filter((v) => {
                return cbobj.cbSchedule.group_id === v.group_id;
              });
              // const scheduleData = await operator.getSchedule(
              //   selectedcb && selectedcb.cbSchedule.group_id
              // );
              setSelectedSchedules(scheduleData);
              // console.log(scheduleData);
              infoOpen();
            }}
            sx={{
              border: "solid",
              backgroundColor: cbobj.cbSchedule?.id ? "red" : "green",
              overflow: "auto",
              height: "auto",
            }}
            item
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                align="center"
                noWrap={false}
                sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
              >
                {cbobj.cbSchedule?.id
                  ? `스케쥴 ON(#${cbobj.cbSchedule.id})`
                  : "스케쥴 OFF"}
              </Typography>
              <Box
                sx={{ width: "100%" }}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                flexWrap="wrap"
                alignItems="center"
              >
                {schedules.result
                  .filter((v) => {
                    return cbobj.cbSchedule?.group_id === v.group_id;
                  })
                  .map((t) => (
                    <Chip
                      size="small"
                      color={t.type ? "primary" : "error"}
                      label={t.time}
                      sx={{ margin: "1px" }}
                    ></Chip>
                  ))}
              </Box>
            </Box>
          </Grid>
          {/* right image (6) */}
          <Grid
            xs={1}
            sx={{
              backgroundImage: `url(${Circuit})`,
              height: "32px",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            item
          ></Grid>
          <Grid xs={1} sx={{}} item>
            <Checkbox
              color="default"
              checked={selectedCBs.includes(String(cbobj.id))}
              onChange={(e) => handleChange(e, cbobj)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid xs={1} sx={{}} item>
            {cbNext && (
              <Checkbox
                color="default"
                checked={selectedCBs.includes(String(cbNext.id))}
                onChange={(e) => handleChange(e, cbNext)}
                inputProps={{ "aria-label": "controlled" }}
              />
            )}
          </Grid>

          {/* left image (6) */}
          {cbNext && (
            <React.Fragment>
              <Grid
                xs={1}
                sx={{
                  backgroundImage: `url(${Circuit})`,
                  height: "32px",
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
                item
              ></Grid>

              <Grid
                xs={2}
                onClick={async () => {
                  setSelectedcb(cbNext);
                  // 여기
                  console.log(cbNext);
                  if (!!!cbNext.cbSchedule) {
                    return;
                  }
                  const scheduleData = schedules.result.filter((v) => {
                    return cbNext.cbSchedule.group_id === v.group_id;
                  });
                  // const scheduleData = await operator.getSchedule(
                  //   selectedcb && selectedcb.cbSchedule.group_id
                  // );
                  setSelectedSchedules(scheduleData);
                  // console.log(scheduleData);
                  infoOpen();
                }}
                sx={{
                  border: "solid",
                  backgroundColor: cbNext.cbSchedule?.id ? "red" : "green",
                  overflow: "auto",
                  height: "auto",
                }}
                item
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    align="center"
                    noWrap={false}
                    sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
                  >
                    {cbNext.cbSchedule?.id
                      ? `스케쥴 ON(#${cbNext.cbSchedule.id})`
                      : "스케쥴 OFF"}
                  </Typography>
                  <Box
                    sx={{ width: "100%" }}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    {schedules.result
                      .filter((v) => {
                        return cbNext.cbSchedule?.group_id === v.group_id;
                      })
                      .map((t) => (
                        <Chip
                          size="small"
                          color={t.type ? "error" : "primary"}
                          label={t.time}
                          sx={{ margin: "1px" }}
                        ></Chip>
                      ))}
                  </Box>
                </Box>
              </Grid>

              {/* number */}
              <Grid xs={1} sx={{ border: "solid" }} item>
                <Typography
                  align="center"
                  noWrap={false}
                  sx={{ wordBreak: "keep-all", fontSize: "0.5rem" }}
                >
                  #{cbNext && cbNext.alias}
                </Typography>
              </Grid>

              <Grid xs={1} sx={{ border: "solid" }} item></Grid>
            </React.Fragment>
          )}
          {!cbNext && (
            <React.Fragment>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
              <Grid xs={1} sx={{}} item></Grid>
            </React.Fragment>
          )}
        </Grid>
      </Paper>
    );
  };

  const powerDrawing = (power) => {
    const powerSwitch = (power) => {
      return (
        <Paper sx={{ p: 1, m: 1, height: "100%" }}>
          <Grid
            container
            flexDirection="row"
            alignContent="center"
            display="flex"
            justifyContent="center"
            sx={{}}
          >
            <Grid xs={2} sx={{}}></Grid>
            <Grid xs={1} sx={{}} item></Grid>
            <Grid
              xs={2}
              sx={{}}
              container
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.amp}A</Typography>
              </Box>
              <Box
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.kw}kW</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="center"
                alignContent="center"
                sx={{
                  border: "solid",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: "auto" }}>{power.volt}V</Typography>
              </Box>
            </Grid>
            <Grid xs={3} sx={{ padding: "0px" }}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundImage: `url(${Power})`,

                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    width: "40%",
                    height: "100%",
                  }}
                >
                  <Chip
                    variant="contained"
                    // onClick={async () => {
                    //   const isOn = selectedPanel.switch;
                    //   const dialog = isOn
                    //     ? "전원을 끄시겠습니까"
                    //     : "전원을 키시겠습니까";

                    //   const confirm = window.confirm(
                    //     `${selectedPanel.title} ${dialog} `
                    //   );
                    //   if (confirm) {
                    //     const { status: a } = await operator.setPanelSwitch(
                    //       panel_id,
                    //       !isOn
                    //     );

                    //     if (a) {
                    //       enqueueSnackbar("성공", { variant: "success" });
                    //       setRefresh(true);
                    //       setRefresh(false);

                    //       // window.location.reload(false);
                    //     } else {
                    //       enqueueSnackbar(a.error, { variant: "error" });
                    //       // window.location.reload(false);
                    //       setRefresh(true);
                    //       setRefresh(false);
                    //     }
                    //   }
                    // }}
                    label={power.switch ? "ON" : "OFF"}
                    size="small"
                    color={power.switch ? "error" : "primary"}
                  ></Chip>
                </Box>
              </Box>
            </Grid>
            <Grid xs={1} sx={{}}></Grid>
            <Grid xs={1} sx={{}}></Grid>
            <Grid xs={2} sx={{}} item></Grid>
          </Grid>
        </Paper>
      );
    };

    let circuitSwitch = (type) => {
      if (type === "left") {
        return (
          data &&
          data.map((v, i) => {
            return LeftCircuit(v);
          })
        );
      } else if (type === "right") {
        return (
          data &&
          data.map((v, i) => {
            return rightCircuit(v);
          })
        );
      } else {
        return (
          data &&
          data.map((v, i) => {
            if (i % 2 === 0) {
              return centerPower(v, data[i + 1]);
            } else {
              return null;
            }
          })
        );
      }
    };
    return (
      <React.Fragment>{circuitSwitch(selectedPanel.direction)}</React.Fragment>
    );
  };

  useEffectOnce(async () => {
    const operator = new Operator(user.id);
    setoperator(operator);

    if (user.is_admin) {
      const cc = await operator.getCC(cc_id);
      setcc(cc.result);
    } else {
      const cc = await operator.getCCs();
      console.log("어드민 cc", cc);
      setcc(cc.result);
    }
    operator.getUsers();

    const cb = await operator.getCBs(panel_id);
    const scheduleGroupsData = await operator.getSchedulegroup();
    const schedulesData = await operator.getSchedules();
    const cbSchedulesData = await operator.getCBschedules();
    console.log("씨비 스케쥴 데이터", cbSchedulesData);
    const sorted = cb.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    const cbSchedulesFiltered = cbSchedulesData.result.filter((v) => {
      return v.panel_id === Number(panel_id);
    });

    const addingCBScheduleData = sorted.map((v) => {
      const [matched] = cbSchedulesFiltered.filter((t) => {
        return t.cb_id === v.id;
      });
      return { ...v, cbSchedule: matched };
    });
    console.log("기둘", addingCBScheduleData);
    console.log("씨비 데이터2222", addingCBScheduleData);

    console.log("씨비 scheduleGroupsData", scheduleGroupsData);
    console.log("씨비 schedulesData", schedulesData);
    console.log("씨비 cbSchedulesData", cbSchedulesData);
    setScheduleGroups(scheduleGroupsData);
    setSchedules(schedulesData);
    setCBSchedules(cbSchedulesData);
    let totalamp = 0;
    cb.map((v) => {
      totalamp += v.amp;
    });

    let panel = await operator.getPanelbyPanelId(panel_id);
    setSelectedPanel(panel);
    setData(addingCBScheduleData);
  });

  React.useEffect(() => {
    if (refresh) {
      (async () => {
        const operator = new Operator(user.id);
        setoperator(operator);

        if (user.is_admin) {
          const cc = await operator.getCC(cc_id);
          setcc(cc.result);
        } else {
          const cc = await operator.getCCs();
          console.log("어드민 cc", cc);
          setcc(cc.result);
        }
        operator.getUsers();

        const cb = await operator.getCBs(panel_id);
        const scheduleGroupsData = await operator.getSchedulegroup();
        const schedulesData = await operator.getSchedules();
        const cbSchedulesData = await operator.getCBschedules();
        const sorted = cb.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }
          if (a.index < b.index) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        const cbSchedulesFiltered = cbSchedulesData.result.filter((v) => {
          return v.panel_id === Number(panel_id);
        });

        const addingCBScheduleData = sorted.map((v) => {
          const [matched] = cbSchedulesFiltered.filter((t) => {
            return t.cb_id === v.id;
          });
          return { ...v, cbSchedule: matched };
        });
        console.log("씨비 데이터2222", addingCBScheduleData);

        console.log("씨비 scheduleGroupsData", scheduleGroupsData);
        console.log("씨비 schedulesData", schedulesData);
        console.log("씨비 cbSchedulesData", cbSchedulesData);
        setScheduleGroups(scheduleGroupsData);
        setSchedules(schedulesData);
        setCBSchedules(cbSchedulesData);
        let totalamp = 0;
        cb.map((v) => {
          totalamp += v.amp;
        });

        let panel = await operator.getPanelbyPanelId(panel_id);
        setSelectedPanel(panel);
        setData(addingCBScheduleData);
        setRefresh(false);
      })();
    }
  }, [refresh]);

  const params = { refresh, setRefresh, ...data };

  return (
    <Page title={"메뉴"}>
      {/* <Header /> */}

      {data ? (
        <React.Fragment>
          <Card sx={{ width: "100vw", height: "44px" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "100%", textAlign: "center" }}
            >
              <ArrowBackIcon
                onClick={() => {
                  if (user.is_admin) return history.goBack();
                  if (user.is_manager) return history.goBack();
                  if (user.is_user) return history.goBack();
                }}
                sx={{ m: 1 }}
              />
              <Typography sx={{ mr: 4 }}>
                {cc.title} {selectedPanel.title} 스케쥴 관리
              </Typography>
              <Link href={`/scheduler`} underline={"none"} sx={{ mr: 1 }}>
                <Typography variant="body2">스케쥴 추가</Typography>
              </Link>
              {/* <div></div> */}
            </Box>
          </Card>
          <Box>{powerDrawing(selectedPanel, "center")}</Box>
          <Box>
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              sx={{ position: "absolute", bottom: 16, right: 16 }}
              onClick={() => {
                open();
              }}
            >
              선택 스케쥴 저장
            </Fab>
            <Fab
              variant="extended"
              color="error"
              aria-label="add"
              sx={{ position: "absolute", bottom: 16, left: 16 }}
              onClick={async () => {
                const confirmMessage = window.confirm(
                  "선택하신 차단기의 스케쥴을 삭제 하시겠습니까"
                );
                if (confirmMessage) {
                  for await (const cb of selectedCBs) {
                    await operator.deleteCBschedule(cb);
                  }
                  alert("삭제 완료");
                  setRefresh(true);
                  return;
                }
              }}
            >
              선택 스케쥴 삭제
            </Fab>
          </Box>
          <Box sx={{ width: "100%", height: "100px" }}></Box>
          <InfoModal>
            <Paper>
              <Typography variant="body2" sx={{ p: 1 }}>
                할당된 스케쥴
              </Typography>
              <Card>
                <CardContent>
                  {selectedSchedules &&
                    selectedSchedules.map((t) => {
                      return (
                        <Chip
                          clickable
                          sx={{ margin: "0.5px" }}
                          key={t.time + t.type}
                          variant="contained"
                          color={t.type ? "primary" : "error"}
                          size="small"
                          label={t.time + " " + String(t.type ? "ON" : "OFF")}
                        ></Chip>
                      );
                    })}
                </CardContent>
                <CardActions>
                  <Button size="small" fullWidth onClick={infoClose}>
                    확인
                  </Button>
                </CardActions>
              </Card>
            </Paper>
          </InfoModal>
          <Modal>
            <Card sx={{ width: "80vw" }}>
              <Container>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{ m: 1 }}
                >
                  스케쥴을 선택해 할당해 주세요
                </Typography>
                <Card></Card>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          스케쥴 번호
                        </TableCell>

                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          스케쥴명
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          예약
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scheduleGroups &&
                        scheduleGroups.result.map((row, i) => (
                          <TableRow
                            key={row.title + String(i)}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">{row.id}번</TableCell>

                            <TableCell align="center">
                              {row.title}
                              <Box sx={{ display: "block" }}></Box>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                aria-describedby={id}
                                variant="contained"
                                onClick={(e) => {
                                  handleClick(e);
                                  setSelectedGroupId(row.id);
                                }}
                              >
                                스케쥴 확인
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      {/* <Popover
                        id={id}
                        open={popen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      > */}
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ p: 1 }}
                      >
                        <Typography sx={{ p: 2 }}>스케쥴</Typography>
                        <Chip
                          sx={{ p: 2 }}
                          label={"예약할당"}
                          variant="outlined"
                          onClick={async () => {
                            const cbScheduleObjs = selectedCBs.map((v) => {
                              console.log(v);
                              return {
                                cb_id: Number(v),
                                group_id: Number(selectedGroupId),
                                panel_id,
                              };
                            });

                            for await (const cb of selectedCBs) {
                              await operator.deleteCBschedule(cb);
                            }

                            const saveSchedule =
                              await operator.createCBschedule(cbScheduleObjs);
                            // TODO
                            console.log("saveSchedule", saveSchedule);
                            if (saveSchedule.status) {
                              setSelectedcb([]);
                              setSelectedGroupId(null);
                              alert("할당 완료");
                              close();
                              setRefresh(true);
                            } else {
                              alert("할당 실패, 에러코드 : 2000");
                              setSelectedGroupId(null);
                              setSelectedcb([]);
                              close();
                              setRefresh(true);
                              return;
                            }
                          }}
                        ></Chip>
                      </Box>
                      <Card sx={{ p: 1 }}>
                        <Container>
                          {schedules &&
                            schedules.result.map((t, j) => {
                              if (t.group_id === selectedGroupId) {
                                return (
                                  <Chip
                                    clickable
                                    sx={{ margin: "0.5px" }}
                                    key={t.time + t.type + String(j)}
                                    variant="contained"
                                    color={t.type ? "primary" : "error"}
                                    size="small"
                                    label={
                                      t.time +
                                      " " +
                                      String(t.type ? "ON" : "OFF")
                                    }
                                  ></Chip>
                                );
                              }
                            })}
                        </Container>
                      </Card>
                      {/* </Popover> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
              <Button onClick={close} fullWidth>
                닫기
              </Button>
            </Card>
          </Modal>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Page>
  );
};

export default Home;
